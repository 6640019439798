import React, { useState, useEffect } from "react";
import LayoutComponent from "../../shared/LayoutComponent";
import { useContext } from "react";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import api from "../../api";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import { formatToDateString } from "../../utils/date.utils";
import { Calendar } from "react-date-range";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";

const ClientLogs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortedLogData, setSortedLogData] = useState([]);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [crudfilteredData, setCrudFilteredData] = useState([]);
  const [selectedCrud, setSelectedCrud] = useState("All");
  const context = useContext(ExchangeApiContext);

  const { loggedin_user_email } = context;
  const params = {
    user: loggedin_user_email,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/logs", {
          params,
        });

        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.error(error);
      }
    };
    fetchData();
  }, [loggedin_user_email]);

  const formatData = () => {
    return data.map((item, index) => {
      const formattedLogs = item.org_logs.map((log) => {
        const regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+)/;
        const [events, dateTime] = log.split(regex);
        const formattedEvents = events.replace(": Done in", ""); // Remove " Done in" from the events string
        let crudOperation;

        if (events.includes("created")) {
          crudOperation = "Created";
        } else if (events.includes("Deleted")) {
          crudOperation = "Deleted";
        } else if (events.includes("Updated")) {
          crudOperation = "Updated";
        } else if (events.includes("read")) {
          crudOperation = "Read";
        } else {
          crudOperation = "Unknown";
        } // Remove " Done in" from the events string

        const formattedDate = moment(dateTime, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        const formattedTime = moment(
          dateTime,
          "YYYY-MM-DD HH:mm:ss.SSSSSS"
        ).format("YYYY-MM-DD HH:mm:ss");
        return {
          dates: formattedDate,
          times: formattedTime,
          events: formattedEvents,
          crudOperation: crudOperation,
        };
      }).filter(log => log.crudOperation !== "Unknown"); // Filter out logs with Unknown crudOperation

      return {
        key: index,
        org: item.org,
        org_log: formattedLogs,
      };
    });
  };

  // const formattedData = formatData();
  // const currentLog = formattedData.flatMap(({ key, org, org_log }) =>
  //   org_log.map(({ dateTime, events }) => ({
  //     key: key + "-" + dateTime, // Generate a unique key using the combination of `key` and `dateTime`
  //     org,
  //     dateTime,
  //     events,
  //   }))
  // );

  function formatDate(dateTime) {
    const dateObj = new Date(dateTime);
    const formattedDate = dateObj.toLocaleDateString(); // Format the date as per local system's settings
    return formattedDate;
  }

  function formatTime(dateTime) {
    const dateObj = new Date(dateTime);
    const formattedTime = dateObj.toTimeString().split(" ")[0]; // Format the time as per local system's settings
    return formattedTime;
  }
  // const sortedLogData = currentLog.sort((a, b) => {
  //   return new Date(b.dateTime) - new Date(a.dateTime);
  // });
  useEffect(() => {
    const formattedData = formatData();
    const LogData = formattedData.flatMap(({ key, org, org_log }) =>
      org_log.map(({ dates, events, times, crudOperation }) => ({
        key: key + "-" + dates,
        org,
        dates,
        times,
        events,
        crudOperation,
      }))
    );
    const sortedData = LogData.sort((a, b) => {
      return new Date(b.dates) - new Date(a.dates);
    });
    setSortedLogData(sortedData);
  }, [data]);

  //   const sortedData = LogData.sort((a, b) => {
  //     return new Date(b.dates) - new Date(a.dates);
  //   });
  //   setSortedLogData(sortedData);
  // };

  const getFilteredData = (
    { startDate, endDate, sortedLogData },
    errorCallback
  ) => {
    if (!sortedLogData || sortedLogData.length <= 0) return [];

    if (startDate && endDate && startDate > endDate) {
      console.log("Start date cannot be greater than end date");

      return [];
    }

    const formattedStartDate = startDate
      ? new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )
      : null;
    // console.log("formattedstart", formattedStartDate);

    const formattedEndDate = endDate
      ? new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59
        )
      : null;
    // console.log("formattedend", formattedEndDate);

    const filteredData = sortedLogData.filter((item) => {
      const date = new Date(item["dates"]);
      return (
        (!formattedStartDate || date >= formattedStartDate) &&
        (!formattedEndDate || date <= formattedEndDate)
      );
    });

    return filteredData;
  };

  const handleStartDate = (date) => {
    if (date) {
      setStartDate(date);
      const data = getFilteredData({
        startDate: date,
        endDate,
        sortedLogData,
      });
      setFilteredData(data);
      setOverlayOpen(false);
    } else {
      console.log("Date is not available");
    }
  };

  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      const data = getFilteredData({
        startDate,
        endDate: date,
        sortedLogData,
      });
      setFilteredData(data);
      setOverlayOpenEnd(false);
    } else {
      console.log("Date is not available");
    }
  };

  useEffect(() => {
    const datass = getFilteredData({ startDate, endDate, sortedLogData });
    setFilteredData(datass);
  }, [startDate, endDate, sortedLogData]);

  const popoverStartDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => {
          handleStartDate(date); // Close the popover when a date is selected
        }}
        date={startDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );

  const popoverEndDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => handleEndDate(date)}
        date={endDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );
  const uniquecrudNames = [
    ...new Set(sortedLogData.map((item) => item.crudOperation)),
  ];
  useEffect(() => {
    if (selectedCrud === "All") {
      setCrudFilteredData(filteredData);
    } else {
      const crudfilteredData = filteredData.filter(
        (item) => item.crudOperation === selectedCrud
      );

      // Use the filteredData variable here or perform other operations
      setCrudFilteredData(crudfilteredData);
      setCurrentPage(1);
    }
  }, [selectedCrud, filteredData]);

  const handleCrudFilterChange = (crudOperation) => {
    setSelectedCrud(crudOperation);
    setCurrentPage(1);
  };

  const [postsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = crudfilteredData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const csvFilename = "UserLogs.csv";
  const modifiedData = sortedLogData.map(({ events, dateTime, org }) => {
    return {
      Organization: org,
      Date: moment(formatDate(dateTime)).format("DD/MM/YYYY"),
      Time: moment
        .tz(formatTime(dateTime), "HH:mm:ss.SSSSSS", "GMT")
        .tz("Asia/Kathmandu")
        .format("hh:mm A"),
      Event: events,
    };
  });
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [isOverlayOpenEnd, setOverlayOpenEnd] = useState(false);
  return (
    <LayoutComponent>
      <div className="table-content pt-5">
        <div className="container">
          <div className="table-header pt-4">
            <div className="d-flex ">
              <Dropdown>
                {/* <span className="to-span me-2">CRUD:</span> */}
                <Dropdown.Toggle className="group-filter" variant="outline">
                  <i className="bi bi-filter"></i>
                  <span className="mx-2"> {selectedCrud} </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-text-size">
                  <Dropdown.Item onClick={() => handleCrudFilterChange("All")}>
                    All
                  </Dropdown.Item>
                  {uniquecrudNames.map((crudOperation) => (
                    <Dropdown.Item
                      key={crudOperation}
                      onClick={() => handleCrudFilterChange(crudOperation)}
                    >
                      {crudOperation}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <div className="date-container ml-4 pb-1">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popoverStartDate}
                  rootClose
                  rootCloseEvent="mousedown"
                  show={isOverlayOpen} // Pass the overlay visibility state
                  onHide={() => {
                    setOverlayOpen(false); // Close the overlay when clicked outside
                  }}
                >
                  <button
                    className="btn-primary-update text-small"
                    onClick={() => setOverlayOpen(!isOverlayOpen)}
                  >
                    {startDate ? formatToDateString(startDate) : "Start Date"}
                    &nbsp;
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </OverlayTrigger>

                <span className="to-span mx-2">to</span>

                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={popoverEndDate}
                  show={isOverlayOpenEnd} // Pass the overlay visibility state
                  onHide={() => {
                    setOverlayOpenEnd(false); // Close the overlay when clicked outside
                  }}
                >
                  <button
                    className="btn-primary-update text-small"
                    onClick={() => setOverlayOpenEnd(!isOverlayOpenEnd)}
                  >
                    {endDate ? formatToDateString(endDate) : "End Date"}
                    &nbsp;
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div>
              <CSVLink
                data={modifiedData}
                filename={csvFilename}
                className="btn btn-sm btn-primary"
              >
                <span className="svg-icon svg-icon-2"></span>{" "}
                <i className="bi bi-download"></i>
                <span className="mx-2"> Export</span>
              </CSVLink>
            </div>
          </div>

          <div className="table-responsive pt-3">
            <table className="table table-sm pt-3 table-log table-smaller">
              <thead className="log-table-color">
                <tr>
                  <th className="min-w-10px" scope="col">
                    Organization
                  </th>
                  <th className="min-w-10px" scope="col">
                    Date
                  </th>
                  <th className="min-w-10px" scope="col">
                    Time
                  </th>
                  <th className="min-w-10px" scope="col">
                    Events
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <td colSpan={4} className="text-center">Loading...</td>
              ) : (
                <tbody>
                  {currentPosts && currentPosts?.length > 0 ? (
                    currentPosts.map(
                      (
                        { key, org, dates, events, times, crudOperation },
                        index
                      ) => (
                        <tr
                          key={`${key}-${dates}-${index}`}
                          className="log-table-padding"
                        >
                          <td className="text-nowrap">{org}</td>
                          <td className="text-nowrap">{dates}</td>
                          <td className="text-nowrap">
                            {moment
                              .tz(formatTime(times), "HH:mm:ss.SSSSSS", "GMT")
                              .tz("Asia/Kathmandu")
                              .format("hh:mm A")}
                          </td>
                          <td>{events}</td>
                          {/* <td>{crudOperation}</td> */}
                        </tr>
                      )
                    )
                  ) : (
                    <td colSpan={4} className="text-center">No Data Available</td>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="table-header pt-3 pb-3">
            <p className="text-lgt align-items-start flex-column">
              {/* <span className="card-label mb-1">
            Showing 1 to 10 of 50 entries
          </span> */}
            </p>

            {crudfilteredData ? (
              <div className="card-toolbar">
                {/* ... */}
                <ReactPaginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(crudfilteredData.length / postsPerPage)} // Use currentLog instead of formattedData
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  containerClassName={"pagination"}
                  pageLinkClassName={"page-number"}
                  previousLinkClassName={"page-number"}
                  nextLinkClassName={"page-number"}
                  activeLinkClassName={"active"}
                />
              </div>
            ) : (
              <div className="loading">Loading..</div>
            )}
          </div>
          <div className="table-header pt-3 pb-3">
            <p className="text-lgt align-items-start flex-column"></p>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default ClientLogs;
