import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";

import ViewUser from "../components/Client_Admin/ViewUser";

import CreateUser from "../components/Client_Admin/CreateUser";
// import SupportPortalModal from "../components/Supportportal";
import CreateAdminUser from "../components/Super_Admin/CreateAdminUser";
import ViewAdminUser from "../components/Super_Admin/ViewAdminUser";
import CreatePage from "../components/createPage";
import ViewPage from "../components/ViewPage";
import CreateNormalUser from "../components/Super_Admin/CreateNormalUser";
import ExchangeApiContext from "../context/Exchangecontext/exchangeapicontext";

import ChartPage from "../components/chartpage";
import LogsPage from "../components/LogsPages";
import { useContext, useEffect } from "react";

const Routers = () => {
  const context = useContext(ExchangeApiContext);
  const { role, getexchangetoken } = context;
  useEffect(() => {
    getexchangetoken();
  });
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<ViewPage />} />
        <Route
          path="/create-admin"
          element={role === "super_admin" && <CreateAdminUser />}
        />
        <Route
          path="/create-user"
          element={role === "super_admin" && <CreateNormalUser />}
        />
        <Route
          path="/create"
          element={role === "client_admin" && <CreateUser />}
        />
        <Route path="/log" element={<LogsPage />} />
        <Route path="/chart" element={<ChartPage />} />
      </Routes>
    </HashRouter>
  );
};
export default Routers;
