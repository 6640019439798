import api from "../api";

const apiService = {
  exchangeToken: async () => {
    try {
      const response = await api.get(`/exchange-token`);

      const { exp_time, user_data } = response.data;

      // Set exp_time and user_data in local storage
      // localStorage.setItem("expTime", exp_time);
      localStorage.setItem("userData", JSON.stringify(user_data));

      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getRole: () => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    return storedUserData?.role || "";
  },

  getuseremail: () => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    return storedUserData?.body || "";
  },
};

export default apiService;
