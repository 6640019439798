// import React, { useState, useEffect, useContext, useRef } from "react";
// import { useReactToPrint } from "react-to-print";
// import ReactToPrint from "react-to-print";
// import exporting from "highcharts/modules/exporting";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import LayoutComponent from "../../shared/LayoutComponent";
// import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
// import api from "../../api";
// import LoadingSpinner from "../spinner";
// import Dropdown from "react-bootstrap/Dropdown";

// // Initialize the exporting module
// exporting(Highcharts);

// Highcharts.setOptions({
//   exporting: {
//     enabled: true,
//     buttons: {
//       contextButton: {
//         menuItems: ["downloadPDF", "printChart"],
//         symbol: "menu",
//         symbolSize: 12,
//       },
//     },
//   },
// });

// const PrintableContentdisplay = ({
//   TotalstorageUsageOptions,
//   individualStorageUsageOptions,
//   PayasyougoIndividualStorageUsuageOptions,
//   PayasyouGoTotalstorageUsageOptions,
//   payAsYouGoOnly,
// }) => (
//   <div>
//     {payAsYouGoOnly ? (
//       <div className="row mb-4 ">
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             hyhhjhjjhj
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={PayasyouGoTotalstorageUsageOptions}
//             />
//           </div>
//         </div>
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={PayasyougoIndividualStorageUsuageOptions}
//             />
//           </div>
//         </div>
//       </div>
//     ) : (
//       <div className="row mb-4 ">
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={TotalstorageUsageOptions}
//             />
//           </div>
//         </div>
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={individualStorageUsageOptions}
//             />
//           </div>
//         </div>
//       </div>
//     )}
//   </div>
// );

// const PrintableContent = ({
//   TotalstorageUsageOptions,
//   individualStorageUsageOptions,
//   PayasyougoIndividualStorageUsuageOptions,
//   PayasyouGoTotalstorageUsageOptions,
//   payAsYouGoOnly,
// }) => (
//   <div className="print-only">
//     {payAsYouGoOnly ? (
//       <div className="row mb-4 ">
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             TESDGJKHLJ:KGCJHVJB
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={PayasyouGoTotalstorageUsageOptions}
//             />
//           </div>
//         </div>
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={PayasyougoIndividualStorageUsuageOptions}
//             />
//           </div>
//         </div>
//       </div>
//     ) : (
//       <div className="row mb-4 ">
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={TotalstorageUsageOptions}
//             />
//           </div>
//         </div>
//         <div className="col-lg-6">
//           <div className="card" style={{ height: "100%" }}>
//             <HighchartsReact
//               highcharts={Highcharts}
//               options={individualStorageUsageOptions}
//             />
//           </div>
//         </div>
//       </div>
//     )}
//   </div>
// );

// const SuperAdminChart = () => {
//   const [selectedGroup, setSelectedGroup] = useState("");
//   const [filteredData, setFilteredData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [data, setData] = useState([]);
//   const [payAsYouGoOnly, setPayAsYouGoOnly] = useState(false);
//   const context = useContext(ExchangeApiContext);
//   const { loggedin_user_email } = context;
//   const params = {
//     user: loggedin_user_email,
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await api.get("/reports", {
//           params,
//         });
//         const staticData = response.data;
//         setData(staticData);
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (data.length === 0) {
//       setIsLoading(true);
//       return;
//     }
//     const defaultDataItem = data.find(
//       (item) => item.is_pay_as_you_go === payAsYouGoOnly
//     );
//     if (defaultDataItem) {
//       setSelectedGroup(defaultDataItem.Group);
//       const filteredData = data.filter(
//         (item) =>
//           item.Group === defaultDataItem.Group &&
//           item.is_pay_as_you_go === payAsYouGoOnly
//       );
//       setFilteredData(filteredData);
//     }
//     setIsLoading(false);
//   }, [data, payAsYouGoOnly]);

//   const handleGroupSelection = (group) => {
//     setSelectedGroup(group);
//     const filteredData = data.filter(
//       (item) => item.Group === group && item.is_pay_as_you_go === payAsYouGoOnly
//     );
//     setFilteredData(filteredData);
//   };

//   const uniqueGroupNames = Array.from(
//     new Set(
//       data
//         .filter((item) => item.is_pay_as_you_go === payAsYouGoOnly)
//         .map((item) => item.Group)
//     )
//   );

//   const totalStorage = filteredData.reduce((sum, item) => {
//     if (item.Group === selectedGroup) {
//       return item.bucket_capacity_GB;
//     } else {
//       return sum + item.bucket_capacity_GB;
//     }
//   }, 0);

//   const providedStorage = filteredData.reduce(
//     (total, item) => total + item.user_capacity,
//     0
//   );

//   const usedStorage = filteredData.reduce(
//     (total, item) => total + item.storage_in_GB,
//     0
//   );

//   const userprovidedStorage = providedStorage - usedStorage;

//   const remainingStorage = totalStorage - providedStorage;

//   const TotalstorageUsageOptions = {
//     chart: {
//       type: "pie",
//     },
//     title: {
//       text: `Total Allocated Storage (${selectedGroup})`,
//     },
//     xAxis: {
//       title: {
//         text: "",
//       },
//     },
//     yAxis: {
//       title: {
//         text: "Storage Usage(GB)",
//       },
//       visible: true,
//     },

//     plotOptions: {
//       pie: {
//         innerSize: "60%",
//         colors: ["#0075a4", "#28A745", "#DC3545"],
//       },
//     },

//     tooltip: {
//       formatter: function () {
//         let message = `<b>${this.point.name}</b>: `;
//         if (this.point.name === "Used Storage(GB)") {
//           const decimalValue = this.point.y - Math.floor(this.point.y);
//           const formattedValue =
//             decimalValue !== 0 ? this.point.y.toFixed(2) : this.point.y;
//           message += `${formattedValue}GB`;
//         } else if (this.point.name === "Remaining Storage(GB)") {
//           message += `${this.point.y}GB (Total Storage: ${totalStorage}GB)`;
//         } else if (
//           this.point.name === "Remaining Storage Allocated to Users(GB)"
//         ) {
//           message += `${this.point.y.toFixed(
//             2
//           )}GB (Total Storage Allocated to Users: ${providedStorage}GB)`;
//         }
//         return message;
//       },
//     },

//     series: [
//       {
//         data: [
//           {
//             name: "Remaining Storage(GB)",
//             y: remainingStorage,
//           },
//           {
//             name: "Remaining Storage Allocated to Users(GB)",
//             y: userprovidedStorage,
//           },
//           { name: "Used Storage(GB)", y: usedStorage },
//         ],
//       },
//     ],
//   };

//   const individualStorageUsageOptions = {
//     chart: {
//       type: "column",
//     },
//     title: {
//       text: `Individual User Storage  (${selectedGroup})`,
//     },
//     xAxis: {
//       categories: filteredData.map((item) => item.User),
//     },
//     yAxis: {
//       title: {
//         text: "Storage Usage(GB)",
//       },
//     },

//     tooltip: {
//       formatter: function () {
//         const decimalValue = this.y - Math.floor(this.y);
//         const formattedValue = decimalValue !== 0 ? this.y.toFixed(2) : this.y;
//         return (
//           "<b>" +
//           this.series.name +
//           "</b><br>" +
//           this.x +
//           "<br>" +
//           "Storage: " +
//           formattedValue +
//           " GB"
//         );
//       },
//     },
//     series: [
//       {
//         name: "Allocated User Storage",
//         data: filteredData.map((item) => item.user_capacity),
//         color: "#335873",
//       },
//       {
//         name: "Used Storage",
//         data: filteredData.map((item) => item.storage_in_GB),
//         color: "#529936",
//       },
//     ],
//   };

//   const PayasyougoIndividualStorageUsuageOptions = {
//     chart: {
//       type: "column",
//     },
//     title: {
//       text: `Individual Storage Usage (${selectedGroup})`,
//     },
//     xAxis: {
//       categories: filteredData
//         .filter((item) => item.is_pay_as_you_go)
//         .map((item) => item.User),
//     },
//     yAxis: {
//       title: {
//         text: "Storage Usage(GB)",
//       },
//     },
//     series: [
//       {
//         name: "Individual Usage",
//         data: filteredData
//           .filter((item) => item.is_pay_as_you_go)
//           .map((item) => item.storage_in_GB),
//         color: "#1b5d9c",
//       },
//     ],
//   };

//   const PayasyouGoTotalstorageUsageOptions = payAsYouGoOnly
//     ? {
//         chart: {
//           type: "column",
//         },
//         title: {
//           text: `Total Storage Usage (${selectedGroup})`,
//         },
//         xAxis: {
//           categories: [selectedGroup],
//         },
//         yAxis: {
//           title: {
//             text: "Storage Usage(GB)",
//           },
//         },
//         tooltip: {
//           formatter: function () {
//             const decimalValue = this.y - Math.floor(this.y);
//             const formattedValue =
//               decimalValue !== 0 ? this.y.toFixed(2) : this.y;
//             return "<b>" + this.point.name + "</b>: " + formattedValue + "GB";
//           },
//         },
//         series: [
//           {
//             name: "Total Usage",
//             data: [{ name: selectedGroup, y: usedStorage }],
//             color: "#529936",
//           },
//         ],
//       }
//     : null;

//   const togglePayAsYouGo = () => {
//     setPayAsYouGoOnly(!payAsYouGoOnly);
//   };

//   const componentRef = useRef();

//   // const handlePrint = useReactToPrint({
//   //   content: () => componentRef.current,
//   //   documentTitle: "Storage Usage Charts",
//   // });

//   return (
//     <div>
//       {isLoading ? (
//         <LoadingSpinner />
//       ) : (
//         <LayoutComponent>
//           <div className="App bg-app pt-4">
//             <div className="container-fluid pt-4">
//               <div className="table-header pb-3">
//                 <div className="d-flex align-items-center">
//                   <Dropdown>
//                     <Dropdown.Toggle className="group-filter" variant="outline">
//                       <i className="bi bi-filter"></i>
//                       <span className="mx-2">{selectedGroup || "select"}</span>
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu className="dropdown-text-size">
//                       {uniqueGroupNames.map((group, index) => (
//                         <Dropdown.Item
//                           key={index}
//                           onClick={() => handleGroupSelection(group)}
//                           active={selectedGroup === group}
//                         >
//                           {group}
//                         </Dropdown.Item>
//                       ))}
//                     </Dropdown.Menu>
//                   </Dropdown>

//                   <h4 className="card-title align-items-start flex-column">
//                     <span className="card-label fw-bold fs-3 mb-1">
//                       {/* Request History */}
//                     </span>
//                   </h4>
//                 </div>
//                 <div className="card-toolbar">
//                   <ReactToPrint
//                     trigger={() => (
//                       <button
//                         type="button"
//                         className="btn btn-sm btn-primary"
//                         onClick={() => window.print()}
//                       >
//                         {" "}
//                         <i className="bi bi-download"></i>
//                         <span className="mx-2"> Download Full Reports</span>
//                       </button>
//                     )}
//                     content={() => componentRef.current}
//                   />

//                   {/* <button
//                     type="button"
//                     className="btn btn-sm btn-primary"
//                     onClick={handlePrint}
//                   >
//                     <i className="bi bi-download"></i>
//                     <span className="mx-2"> Download Full Reports</span>
//                   </button> */}
//                 </div>
//               </div>

//               <div className="col-lg-2">
//                 <div className="form-rem mb-3">
//                   <div className="form-check mb-2">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="exampleCheck1"
//                       checked={payAsYouGoOnly}
//                       onChange={togglePayAsYouGo}
//                     />
//                     <label
//                       className="form-check-label "
//                       htmlFor="exampleCheck1"
//                     >
//                       Pay As You Go
//                     </label>
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 <PrintableContentdisplay
//                   TotalstorageUsageOptions={TotalstorageUsageOptions}
//                   individualStorageUsageOptions={individualStorageUsageOptions}
//                   PayasyougoIndividualStorageUsuageOptions={
//                     PayasyougoIndividualStorageUsuageOptions
//                   }
//                   PayasyouGoTotalstorageUsageOptions={
//                     PayasyouGoTotalstorageUsageOptions
//                   }
//                   payAsYouGoOnly={payAsYouGoOnly}
//                   styleVariant="show"
//                 />

//                 <div ref={componentRef}>
//                   <PrintableContent
//                     TotalstorageUsageOptions={TotalstorageUsageOptions}
//                     individualStorageUsageOptions={
//                       individualStorageUsageOptions
//                     }
//                     PayasyougoIndividualStorageUsuageOptions={
//                       PayasyougoIndividualStorageUsuageOptions
//                     }
//                     PayasyouGoTotalstorageUsageOptions={
//                       PayasyouGoTotalstorageUsageOptions
//                     }
//                     payAsYouGoOnly={payAsYouGoOnly}
//                   />
//                 </div>
//               </div>
//               {/* <div ref={componentRef}>
//                 <PrintableContent
//                   TotalstorageUsageOptions={TotalstorageUsageOptions}
//                   individualStorageUsageOptions={individualStorageUsageOptions}
//                   PayasyougoIndividualStorageUsuageOptions={
//                     PayasyougoIndividualStorageUsuageOptions
//                   }
//                   PayasyouGoTotalstorageUsageOptions={
//                     PayasyouGoTotalstorageUsageOptions
//                   }
//                   payAsYouGoOnly={payAsYouGoOnly}
//                 />
//               </div> */}
//             </div>
//           </div>
//         </LayoutComponent>
//       )}
//     </div>
//   );
// };

// export default SuperAdminChart;
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from "react-to-print";
import exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import LayoutComponent from "../../shared/LayoutComponent";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import api from "../../api";
import LoadingSpinner from "../spinner";
import Dropdown from "react-bootstrap/Dropdown";
import geneseLogo from "../../assests/images/logo.png";

const PrintPreview = ({
  TotalstorageUsageOptions,
  individualStorageUsageOptions,
  PayasyougoIndividualStorageUsuageOptions,
  PayasyouGoTotalstorageUsageOptions,
  payAsYouGoOnly,
}) => {
  return (
    <div className="print-only">
      <div>
        <img
          src={geneseLogo}
          alt="logo"
          width="70px"
          height="70px"
          // style={{
          //   marginTop: "-8px",
          //   marginRight: "10px",
          //   textAlign: "start",
          //   justifyContent: "flex-start",
          // }}
        />

        <h3
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-60px",
          }}
        >
          Genese Cloud Storage Solution
        </h3>
      </div>

      {payAsYouGoOnly ? (
        <div>
          <div>
            <div className="row mb-4 pt-5">
              <div className="col-lg-8">
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={PayasyouGoTotalstorageUsageOptions}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-4 pt-5">
              <div className="col-lg-8 ">
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={PayasyougoIndividualStorageUsuageOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row mb-4 pt-5 ">
            <div className="col-lg-8">
              <div
                className="card"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={TotalstorageUsageOptions}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4 pt-5">
            <div className="col-lg-8">
              <div
                className="card"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={individualStorageUsageOptions}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PrintPreviewShow = ({
  TotalstorageUsageOptions,
  individualStorageUsageOptions,
  PayasyougoIndividualStorageUsuageOptions,
  PayasyouGoTotalstorageUsageOptions,
  payAsYouGoOnly,
}) => {
  return (
    <div>
      {payAsYouGoOnly ? (
        <div className="row mb-4 ">
          <div className="col-lg-6">
            <div className="card" style={{ height: "100%" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={PayasyouGoTotalstorageUsageOptions}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card" style={{ height: "100%" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={PayasyougoIndividualStorageUsuageOptions}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row mb-4 ">
          <div className="col-lg-6">
            <div className="card" style={{ height: "100%" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={TotalstorageUsageOptions}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card" style={{ height: "100%" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={individualStorageUsageOptions}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PrintComponent = () => {
  const componentRef = useRef();

  const [selectedGroup, setSelectedGroup] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [payAsYouGoOnly, setPayAsYouGoOnly] = useState(false);
  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;
  const params = {
    user: loggedin_user_email,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/reports", {
          params,
        });
        const staticData = response.data;
        setData(staticData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      setIsLoading(true);
      return;
    }
    const defaultDataItem = data.find(
      (item) => item.is_pay_as_you_go === payAsYouGoOnly
    );
    if (defaultDataItem) {
      setSelectedGroup(defaultDataItem.Group);
      const filteredData = data.filter(
        (item) =>
          item.Group === defaultDataItem.Group &&
          item.is_pay_as_you_go === payAsYouGoOnly
      );
      setFilteredData(filteredData);
    }
    setIsLoading(false);
  }, [data, payAsYouGoOnly]);

  const handleGroupSelection = (group) => {
    setSelectedGroup(group);
    const filteredData = data.filter(
      (item) => item.Group === group && item.is_pay_as_you_go === payAsYouGoOnly
    );
    setFilteredData(filteredData);
  };

  const uniqueGroupNames = Array.from(
    new Set(
      data
        .filter((item) => item.is_pay_as_you_go === payAsYouGoOnly)
        .map((item) => item.Group)
    )
  );

  const totalStorage = filteredData.reduce((sum, item) => {
    if (item.Group === selectedGroup) {
      return item.bucket_capacity_GB;
    } else {
      return sum + item.bucket_capacity_GB;
    }
  }, 0);

  const providedStorage = filteredData.reduce(
    (total, item) => total + item.user_capacity,
    0
  );

  const usedStorage = filteredData.reduce(
    (total, item) => total + item.storage_in_GB,
    0
  );

  const userprovidedStorage = providedStorage - usedStorage;

  const remainingStorage = totalStorage - providedStorage;

  const TotalstorageUsageOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: `Total Allocated Storage (${selectedGroup})`,
    },
    xAxis: {
      title: {
        text: "",
      },
    },
    yAxis: {
      title: {
        text: "Storage Usage(GB)",
      },
      visible: true,
    },

    plotOptions: {
      pie: {
        innerSize: "60%",
        colors: ["#0075a4", "#28A745", "#DC3545"],
      },
    },

    tooltip: {
      formatter: function () {
        let message = `<b>${this.point.name}</b>: `;
        if (this.point.name === "Used Storage(GB)") {
          const decimalValue = this.point.y - Math.floor(this.point.y);
          const formattedValue =
            decimalValue !== 0 ? this.point.y.toFixed(2) : this.point.y;
          message += `${formattedValue}GB`;
        } else if (this.point.name === "Remaining Storage(GB)") {
          message += `${this.point.y}GB (Total Storage: ${totalStorage}GB)`;
        } else if (
          this.point.name === "Remaining Storage Allocated to Users(GB)"
        ) {
          message += `${this.point.y.toFixed(
            2
          )}GB (Total Storage Allocated to Users: ${providedStorage}GB)`;
        }
        return message;
      },
    },

    series: [
      {
        data: [
          {
            name: "Remaining Storage(GB)",
            y: remainingStorage,
          },
          {
            name: "Remaining Storage Allocated to Users(GB)",
            y: userprovidedStorage,
          },
          { name: "Used Storage(GB)", y: usedStorage },
        ],
      },
    ],
  };

  const individualStorageUsageOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: `Individual User Storage  (${selectedGroup})`,
    },
    xAxis: {
      categories: filteredData.map((item) => item.User),
    },
    yAxis: {
      title: {
        text: "Storage Usage(GB)",
      },
    },

    tooltip: {
      formatter: function () {
        const decimalValue = this.y - Math.floor(this.y);
        const formattedValue = decimalValue !== 0 ? this.y.toFixed(2) : this.y;
        return (
          "<b>" +
          this.series.name +
          "</b><br>" +
          this.x +
          "<br>" +
          "Storage: " +
          formattedValue +
          " GB"
        );
      },
    },
    series: [
      {
        name: "Allocated User Storage",
        data: filteredData.map((item) => item.user_capacity),
        color: "#335873",
      },
      {
        name: "Used Storage",
        data: filteredData.map((item) => item.storage_in_GB),
        color: "#529936",
      },
    ],
  };

  const PayasyougoIndividualStorageUsuageOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: `Individual Storage Usage (${selectedGroup})`,
    },
    xAxis: {
      categories: filteredData
        .filter((item) => item.is_pay_as_you_go)
        .map((item) => item.User),
    },
    yAxis: {
      title: {
        text: "Storage Usage(GB)",
      },
    },
    series: [
      {
        name: "Individual Usage",
        data: filteredData
          .filter((item) => item.is_pay_as_you_go)
          .map((item) => item.storage_in_GB),
        color: "#1b5d9c",
      },
    ],
  };

  const PayasyouGoTotalstorageUsageOptions = payAsYouGoOnly
    ? {
        chart: {
          type: "column",
        },
        title: {
          text: `Total Storage Usage (${selectedGroup})`,
        },
        xAxis: {
          categories: [selectedGroup],
        },
        yAxis: {
          title: {
            text: "Storage Usage(GB)",
          },
        },
        tooltip: {
          formatter: function () {
            const decimalValue = this.y - Math.floor(this.y);
            const formattedValue =
              decimalValue !== 0 ? this.y.toFixed(2) : this.y;
            return "<b>" + this.point.name + "</b>: " + formattedValue + "GB";
          },
        },
        series: [
          {
            name: "Total Usage",
            data: [{ name: selectedGroup, y: usedStorage }],
            color: "#529936",
          },
        ],
      }
    : null;

  const togglePayAsYouGo = () => {
    setPayAsYouGoOnly(!payAsYouGoOnly);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <LayoutComponent>
          <div className="App bg-app pt-4">
            <div className="container-fluid pt-5">
              {/* <div>
                <h6>Chart</h6> */}
              {/* <p className="to-span me-2 mb-2 mt-1">
                  The List are listed below.
                </p> */}
              {/* </div> */}
              <div className="table-header pb-3">
                <div className="d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle className="group-filter" variant="outline">
                      <i className="bi bi-filter"></i>
                      <span className="mx-2">{selectedGroup || "select"}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-text-size">
                      {uniqueGroupNames.map((group, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleGroupSelection(group)}
                          active={selectedGroup === group}
                        >
                          {group}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <h4 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                      {/* Request History */}
                    </span>
                  </h4>
                </div>
                <div className="card-toolbar">
                  <ReactToPrint
                    trigger={() => (
                      // <button onClick={() => window.print()}>Print</button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => window.print()}
                      >
                        <i className="bi bi-download"></i>
                        <span className="mx-2"> Download Full Report</span>
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handlePrint}
                  >
                    <i className="bi bi-download"></i>
                    <span className="mx-2"> Download Full Reports</span>
                  </button> */}
                </div>
              </div>

              <div className="col-lg-2">
                <div className="form-rem mb-3">
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input p-2"
                      id="exampleCheck1"
                      checked={payAsYouGoOnly}
                      onChange={togglePayAsYouGo}
                    />
                    <label
                      className="form-check-label "
                      htmlFor="exampleCheck1"
                    >
                      Pay As You Go
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <PrintPreviewShow
                  TotalstorageUsageOptions={TotalstorageUsageOptions}
                  individualStorageUsageOptions={individualStorageUsageOptions}
                  PayasyougoIndividualStorageUsuageOptions={
                    PayasyougoIndividualStorageUsuageOptions
                  }
                  PayasyouGoTotalstorageUsageOptions={
                    PayasyouGoTotalstorageUsageOptions
                  }
                  payAsYouGoOnly={payAsYouGoOnly}
                />
              </div>

              <div ref={componentRef}>
                <PrintPreview
                  TotalstorageUsageOptions={TotalstorageUsageOptions}
                  individualStorageUsageOptions={individualStorageUsageOptions}
                  PayasyougoIndividualStorageUsuageOptions={
                    PayasyougoIndividualStorageUsuageOptions
                  }
                  PayasyouGoTotalstorageUsageOptions={
                    PayasyouGoTotalstorageUsageOptions
                  }
                  payAsYouGoOnly={payAsYouGoOnly}
                />
              </div>
            </div>
            {/* <div ref={componentRef}>
                <PrintableContent
                  TotalstorageUsageOptions={TotalstorageUsageOptions}
                  individualStorageUsageOptions={individualStorageUsageOptions}
                  PayasyougoIndividualStorageUsuageOptions={
                    PayasyougoIndividualStorageUsuageOptions
                  }
                  PayasyouGoTotalstorageUsageOptions={
                    PayasyouGoTotalstorageUsageOptions
                  }
                  payAsYouGoOnly={payAsYouGoOnly}
                />
              </div> */}
          </div>
        </LayoutComponent>
      )}

      <div ref={componentRef}>
        <PrintPreview />
        <PrintPreviewShow />
      </div>
    </div>
  );
};

const SuperAdminLogs = () => {
  return (
    <div>
      <PrintComponent />
    </div>
  );
};

export default SuperAdminLogs;
