import React, { useEffect, useContext } from "react";

import ExchangeApiContext from "../context/Exchangecontext/exchangeapicontext";
import SuperAdminLogs from "./Super_Admin/SuperAdminLogs";
import ClientLogs from "./Client_Admin/ClientLogs";

const LogsPage = () => {
  const context = useContext(ExchangeApiContext);
  const { role, getexchangetoken } = context;

  useEffect(() => {
    getexchangetoken();
  });

  return (
    <>
      {role === "super_admin" ? (
        <SuperAdminLogs />
      ) : (
        role === "client_admin" && <ClientLogs />
      )}
    </>
  );
};

export default LogsPage;
