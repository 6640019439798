import React, { useState, useEffect, useContext, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useReactToPrint } from "react-to-print";
import exporting from "highcharts/modules/exporting";
import ReactToPrint from "react-to-print";
import LayoutComponent from "../../shared/LayoutComponent";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import api from "../../api";
import LoadingSpinner from "../spinner";
import geneseLogo from "../../assests/images/logo.png";

// Initialize the exporting module
exporting(Highcharts);

Highcharts.setOptions({
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: ["downloadPDF", "printChart"],
        symbol: "menu",
        symbolSize: 12,
      },
    },
  },
});

const PrintPreview = ({
  TotalStorageOptions,
  IndividualStorageOptions,
  IndividualPayAsYouGoOptions,
  TotalPayAsYouGoOptions,
  isPayAsYouGo,
}) => (
  <div className="print-only">
    <div>
      <img
        src={geneseLogo}
        alt="logo"
        width="70px"
        height="70px"
        // style={{
        //   marginTop: "-8px",
        //   marginRight: "10px",
        //   textAlign: "start",
        //   justifyContent: "flex-start",
        // }}
      />

      <h3
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-60px",
        }}
      >
        Genese Cloud Storage Solution
      </h3>
    </div>
    {isPayAsYouGo ? (
      <div className="row mb-4 pt-5">
        <div className="col-lg-8">
          <div
            className="card"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={IndividualPayAsYouGoOptions}
            />
          </div>
        </div>
        <div className="row mb-4 pt-5">
          <div className="col-lg-8">
            <div
              className="card"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={TotalPayAsYouGoOptions}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="row mb-4 pt-5 ">
        <div className="col-lg-8">
          <div
            className="card"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={TotalStorageOptions}
            />
          </div>
        </div>
        <div className="row mb-4 pt-5">
          <div className="col-lg-5">
            <div
              className="card"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={IndividualStorageOptions}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

const PrintPreviewShow = ({
  TotalStorageOptions,
  IndividualStorageOptions,
  IndividualPayAsYouGoOptions,
  TotalPayAsYouGoOptions,
  isPayAsYouGo,
}) => (
  <div>
    {isPayAsYouGo ? (
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="card" style={{ height: "100%" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={IndividualPayAsYouGoOptions}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card" style={{ height: "100%" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={TotalPayAsYouGoOptions}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="card" style={{ height: "100%" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={TotalStorageOptions}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card" style={{ height: "100%" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={IndividualStorageOptions}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

const Clientchart = () => {
  // const [payasyougo, setPayasyouGo] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;

  const params = {
    user: loggedin_user_email,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/reports", {
          params,
        });
        setData(response.data);
        // console.log(response.data);
        // setPayasyouGo(response.data);
        // console.log(response.data.is_pay_as_you_go);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [loggedin_user_email]);

  const isPayAsYouGo = data[0]?.is_pay_as_you_go;
  // console.log(isPayAsYouGo);

  const totalStorage = Array.from(
    new Set(data.map((item) => item.Group))
  ).reduce((total, group) => {
    const groupItems = data.filter((item) => item.Group === group);
    const groupCapacity =
      groupItems.length > 0 ? groupItems[0].bucket_capacity_GB : 0;
    return total + groupCapacity;
  }, 0);

  const providedStorage = data.reduce(
    (total, item) => total + item.user_capacity,
    0
  );

  const usedStorage = data.reduce(
    (total, item) => total + item.storage_in_GB,
    0
  );

  const userprovidedStorage = providedStorage - usedStorage;

  const remainingStorage = totalStorage - providedStorage;

  const TotalStorageOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Total Allocated Storage ",
    },
    xAxis: {
      title: {
        text: "",
      },
    },
    yAxis: {
      title: {
        text: "Storage Usage(GB)",
      },
      visible: true,
    },
    plotOptions: {
      pie: {
        innerSize: "60%",
        colors: ["#0075a4", "#28A745", "#DC3545"],
      },
    },
    tooltip: {
      formatter: function () {
        let message = `<b>${this.point.name}</b>: `;
        if (this.point.name === "Used Storage(GB)") {
          const decimalValue = this.point.y - Math.floor(this.point.y);
          const formattedValue =
            decimalValue !== 0 ? this.point.y.toFixed(2) : this.point.y;
          message += `${formattedValue}GB`;
        } else if (this.point.name === "Remaining Storage(GB)") {
          message += `${this.point.y}GB (Total Storage: ${totalStorage}GB)`;
        } else if (
          this.point.name === "Remaining Storage Allocated to Users(GB)"
        ) {
          message += `${this.point.y.toFixed(
            2
          )}GB (Total Storage Allocated to Users: ${providedStorage}GB)`;
        }
        return message;
      },
    },

    series: [
      {
        name: "Capacity",
        data: [
          {
            name: "Remaining Storage(GB)",
            y: remainingStorage,
          },
          {
            name: "Remaining Storage Allocated to Users(GB)",
            y: userprovidedStorage,
          },
          { name: "Used Storage(GB)", y: usedStorage },
        ],
      },
    ],
  };
  const IndividualStorageOptions = {
    chart: {
      type: "column",
      // scrollablePlotArea: {
      //   minWidth: 600, // Set the minimum width before scrollbar appears
      //   scrollPositionX: 1, // Set the initial scroll position to the rightmost
      //   scrollBarHeight: 6, // Set the height of the scrollbar
      // },
    },
    title: {
      text: "Individual User Storage",
    },
    xAxis: {
      categories: data.map((item) => item.User),
      // scrollbar: {
      //   enabled: true,
      // },
    },
    yAxis: {
      title: {
        text: "Storage in GB",
      },
    },
    tooltip: {
      formatter: function () {
        const decimalValue = this.y - Math.floor(this.y);
        const formattedValue = decimalValue !== 0 ? this.y.toFixed(2) : this.y;
        return (
          "<b>" +
          this.series.name +
          "</b><br>" +
          this.x +
          "<br>" +
          "Storage: " +
          formattedValue +
          " GB"
        );
      },
    },
    series: [
      {
        name: "Allocated User Storage",
        data: data.map((item) => item.user_capacity),
        color: "#335873",
      },
      {
        name: "Used Storage",
        data: data.map((item) => item.storage_in_GB),
        color: "#529936",
      },
    ],
  };

  //for pay as you go true
  const filteredData = data.filter((item) => item.is_pay_as_you_go);
  // Prepare data for the chart

  const totalStorageUsage = filteredData.reduce((accumulator, item) => {
    return accumulator + item.storage_in_GB;
  }, 0);

  const TotalPayAsYouGoOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "Total Storage Usage (Pay as you go)",
    },
    xAxis: {
      categories: ["Total"],
    },
    yAxis: {
      title: {
        text: "Storage Usage (GB)",
      },
    },
    tooltip: {
      formatter: function () {
        const formattedValue = this.point.y.toFixed(2);
        return `<b>${this.series.name}</b>: ${formattedValue} GB`;
      },
    },
    series: [
      {
        name: "Total Usage",
        data: [totalStorageUsage],
        color: "#529936",
      },
    ],
  };

  const chartData = filteredData.map((item) => ({
    name: item.User,
    y: item.storage_in_GB,
  }));

  const IndividualPayAsYouGoOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "Individual Storage Usage (Pay as you go)",
    },
    xAxis: {
      categories: filteredData.map((item) => item.User),
    },
    yAxis: {
      title: {
        text: "Storage  Usage(GB)",
      },
    },
    tooltip: {
      formatter: function () {
        const decimalValue = this.y - Math.floor(this.y);
        const formattedValue = decimalValue !== 0 ? this.y.toFixed(2) : this.y;
        return (
          "<b>" +
          this.series.name +
          "</b><br>" +
          this.x +
          "<br>" +
          "Storage: " +
          formattedValue +
          " GB"
        );
      },
    },
    series: [
      {
        name: "Individual Usage",
        data: chartData,
        color: "#1b5d9c",
      },
    ],
  };

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: "Storage Usage chart",
  // });

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <LayoutComponent>
          <div className="App bg-app pt-4">
            <div className="container-fluid pt-5">
              <div className="table-header pb-3">
                <div className="d-flex align-items-center">
                  <h4 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                      {/* Request History */}
                    </span>
                  </h4>
                </div>
                <div className="card-toolbar">
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => window.print()}
                      >
                        <i className="bi bi-download"></i>
                        <span className="mx-2"> Download Full Report</span>
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>

              <div>
                {data.length === 0 ? (
                  <div>No data available</div>
                ) : (
                  <div>
                    <PrintPreviewShow
                      TotalStorageOptions={TotalStorageOptions}
                      IndividualStorageOptions={IndividualStorageOptions}
                      IndividualPayAsYouGoOptions={IndividualPayAsYouGoOptions}
                      TotalPayAsYouGoOptions={TotalPayAsYouGoOptions}
                      isPayAsYouGo={isPayAsYouGo}
                    />
                    <div ref={componentRef}>
                      <PrintPreview
                        TotalStorageOptions={TotalStorageOptions}
                        IndividualStorageOptions={IndividualStorageOptions}
                        IndividualPayAsYouGoOptions={
                          IndividualPayAsYouGoOptions
                        }
                        TotalPayAsYouGoOptions={TotalPayAsYouGoOptions}
                        isPayAsYouGo={isPayAsYouGo}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </LayoutComponent>
      )}
      <div ref={componentRef}>
        <PrintPreview />
        <PrintPreviewShow />
      </div>
    </div>
  );
};
// const Clientchart = () => {
//   return (
//     <div>
//       <PrintComponent />
//     </div>
//   );
// };

export default Clientchart;
