import React, { useState, useEffect, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../../api";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import FooterComponent from "../../shared/FooterComponent";
import LayoutComponent from "../../shared/LayoutComponent";
import { format } from "date-fns";
import moment from "moment";
import { Calendar } from "react-date-range";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { formatToDateString } from "../../utils/date.utils";

const ViewAdminUser = () => {
  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;
  const queryClient = useQueryClient();

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [userdata, setuserData] = useState([]);
  const [payasyougo, setPayAsYouGo] = useState([]);
  const [currentCapacityValue, setCurrentCapacityValue] = useState();
  const [currentemail, setCurrentemail] = useState();
  const [currentDisplayName, setCurrentDisplayName] = useState();
  const [currentIsGroupAdmin, setCurrentIsGroupAdmin] = useState();
  const [currentIsAccountActive, setCurrentIsAccountActive] = useState();
  const [currentBucketCapacity, setCurrentBucketCapacity] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [postsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [groupfilteredData, setFilteredGroupData] = useState([]);
  const [currentemailpayasyougo, setCurrentEmailPayAsYOUGO] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [isOverlayOpenEnd, setOverlayOpenEnd] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const schema = yup.object().shape({
    currentBucketCapacity: yup
      .string()
      .required("storage Capacity is required")
      .matches(
        /^(0|[1-9]\d*)$/,
        "Storage capacity must be a non-negative number"
      ),

    currentCapacityValue: yup
      .string()
      .required("User Capacity is required")
      .matches(/^(0|[1-9]\d*)$/, "User capacity must be a non-negative number")
      .test(
        "lessThanBucketCapacity",
        "User capacity should be less than storage capacity",
        function (value) {
          const bucketCapacity = parseInt(this.parent.currentBucketCapacity);
          const userCapacity = parseInt(value);
          return userCapacity <= bucketCapacity;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: {
      currentCapacityValue: currentCapacityValue,
      currentBucketCapacity: currentBucketCapacity,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const handleCloseUserModal = () => {
    setShowEditUserModal(false);
    reset();
  };
  const csvFilename = "myUserData.csv";

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentUser = filteredData.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = ({ selected }) => {
  //   setCurrentPage(selected + 1);
  // };
  const params = {
    user: loggedin_user_email,
  };
  useQuery("userData", async () => {
    try {
      const response = await api.get(`/view`, {
        params,
      });
      // console.log(response.data);
      const clientAdminData = JSON.parse(response.data.body);
      setuserData(clientAdminData);
      setPayAsYouGo(clientAdminData.is_pay_as_you_go);
      // console.log(userdata);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  });

  const formatDateTime = (datetime) => {
    const formattedDate = moment(datetime).format("DD/MM/YYYY");
    const formattedTime = moment(datetime)
      .tz("Asia/Kathmandu")
      .format("hh:mm A");
    return { formattedDate, formattedTime };
  };

  const showSubmit = (
    host_email,

    user_capacity,
    is_account_active,
    is_partner_admin,
    bucket_capacity_in_gb
  ) => {
    // setGlobalEditData({
    //   host_email: host_email,
    //   // parent_id: loggedInEmail,
    // });
    setCurrentCapacityValue(user_capacity);
    setCurrentemail(host_email);
    setCurrentIsAccountActive(is_account_active);
    setCurrentIsGroupAdmin(is_partner_admin);
    setCurrentBucketCapacity(bucket_capacity_in_gb);

    setShowEditUserModal(true);
  };

  const handleEditSubmit = () => {
    const postData = {
      email: currentemail,
      display_name: "test",
      is_group_admin: currentIsGroupAdmin,
      user_capacity: currentCapacityValue,
      is_account_active: currentIsAccountActive,
      bucket_capacity: currentBucketCapacity,
    };

    const param1 = {
      loggedInUser: loggedin_user_email,
    };

    // console.log(postData);
    api
      .put(`/update-user/${currentemail}`, postData, {
        params: param1,
      })
      .then(function (response) {
        toast.success("Update successful!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // console.log(response);
        // setGlobalEditData(response.data);
        queryClient.invalidateQueries("userData");

        setShowEditUserModal(false); // close modal after successful submission
        reset();
      })
      .catch(function (error) {
        toast.error("Failed to update user detail!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.log(error);
      });
  };
  const modifiedData = userdata.map((item) => {
    return {
      "Storage Capacity (GB)": item.bucket_capacity_in_gb,
      "Bucket Name": item.bucket_name,
      Group: item.group,
      Email: item.host_email,
      "Is group Admin": item.is_group_admin,
      "Is Account Active ": item.is_account_active,
      "Is Partner Admin": item.is_partner_admin,
      "Is Pay As You GO": item.is_pay_as_you_go,
      "Is Super Admin": item.is_super_admin,
      "Parent Id": item.parent_id,
      "User Capacity": item.user_capacity,
    };
  });

  const deleteUser = (host_email) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#10485aff",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("/delete-user", {
            data: {
              admin_email: loggedin_user_email,
              delete_email: host_email,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "User has been deleted.", "success");

            queryClient.invalidateQueries("userData");
          })
          .catch((error) => {
            // Handle error
          });
      }
    });
  };
  // Get unique group names

  const getFilteredData = ({ startDate, endDate, userdata }) => {
    if (!userdata || userdata.length <= 0) return [];

    if (startDate && endDate && startDate > endDate) {
      console.log("Start date cannot be greater than end date");
      return [];
    }

    const formattedStartDate = startDate
      ? new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )
      : null;

    const formattedEndDate = endDate
      ? new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59,
          999 // Set the milliseconds to 999 to consider local time
        )
      : null;

    const filteredData = userdata.filter((item) => {
      const date = new Date(item["created_datetime"]);

      // Check if the date is valid

      return (
        (!formattedStartDate || date >= formattedStartDate) &&
        (!formattedEndDate || date <= formattedEndDate)
      );
    });

    return filteredData;
  };

  const handleStartDate = (date) => {
    if (date) {
      setStartDate(date);
      const data = getFilteredData({
        startDate: date,
        endDate,
        userdata,
      });
      setFilteredData(data);
      setOverlayOpen(false);
    } else {
      console.log("Date is not available");
    }
  };

  const handleEndDate = (date) => {
    if (date) {
      console.log("End Date is available:", date);
      setEndDate(date);
      const data = getFilteredData({
        startDate,
        endDate: date,
        userdata,
      });
      setFilteredData(data);
      setOverlayOpenEnd(false);
    } else {
      console.log("Date is not available");
    }
  };

  useEffect(() => {
    const dataa = getFilteredData({ startDate, endDate, userdata });
    setFilteredData(dataa);
  }, [startDate, endDate, userdata]);

  const popoverStartDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => {
          handleStartDate(date); // Close the popover when a date is selected
        }}
        date={startDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );

  const popoverEndDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => handleEndDate(date)}
        date={endDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );
  const uniqueGroupNames = [
    ...new Set(userdata.map((groupType) => groupType.group)),
  ];
  useEffect(() => {
    if (selectedGroup === "All") {
      setFilteredGroupData(filteredData);
    } else {
      const groupfilteredData = filteredData.filter(
        (filterData) => filterData.group === selectedGroup
      );
      setFilteredGroupData(groupfilteredData);
      setCurrentPage(1);
    }
  }, [selectedGroup, filteredData]);

  const handleGroupSelection = (groupp) => {
    setSelectedGroup(groupp);
    setCurrentPage(1); // Reset the current page to 1

    // console.log(selectedGroup);
  };

  useEffect(() => {
    // console.log("currentemail", currentemail);
    if (currentemail) {
      const selectedPayAsYouGO = userdata.find(
        (partnerAdminEmail) => partnerAdminEmail.host_email === currentemail
      );
      // console.log("s", selectedPayAsYouGO);

      const currentPayAsYouGo = selectedPayAsYouGO?.is_pay_as_you_go || false;
      setCurrentEmailPayAsYOUGO(currentPayAsYouGo);

      // console.log("payasyougo", currentPayAsYouGo);
    }
  }, [currentemail, userdata]);

  // const formatToDateString = (date) => {
  //   const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //   return date.toLocaleDateString("en-US", options);
  // };

  // const formatToDateString = (date) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${year}-${month}-${day}`;
  // };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentUser = groupfilteredData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleSearch = () => {
    // Check if userdata is defined
    if (!userdata) {
      return;
    }

    // Filter the data based on the search term
    const groupfilteredData = userdata.filter((user) =>
      user.host_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Use the filtered data for further processing
    // ...
    setFilteredGroupData(groupfilteredData);

    // Reset filteredGroupData if search term is empty
    if (searchTerm === "") {
      setSearchTerm("");
      setFilteredGroupData(userdata);
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setFilteredGroupData(userdata);
  };

  return (
    <LayoutComponent>
      <div className="table-content pt-5">
        <div className="container">
          <div>
            <h6>Users List</h6>
            <p className="to-span me-2 mb-2 mt-1">
              The User List are listed below.
            </p>
          </div>
          <div className="table-header pb-1">
            <div className="d-flex align-items-center mt-3">
              <div className="search-container pl-3 pr-3">
                <input
                  type="search"
                  id="form1"
                  className="form-control"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyUp={handleSearch}
                  onClick={handleReset}
                />

                {/* <button
                type="button"
                className="search-button"
                onClick={handleSearch}
              >
                <i className="fas fa-search"></i>
              </button> */}
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  className=" group-filter-super"
                  variant="outline"
                >
                  <i className="bi bi-filter"></i>
                  <span className="mx-2"> Select Group</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-text-size">
                  <Dropdown.Item onClick={() => handleGroupSelection("All")}>
                    All
                  </Dropdown.Item>
                  {uniqueGroupNames.map((group) => (
                    <Dropdown.Item
                      key={group}
                      onClick={() => handleGroupSelection(group)}
                    >
                      {group}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div className="date-container">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popoverStartDate}
                  rootClose
                  rootCloseEvent="mousedown"
                  show={isOverlayOpen} // Pass the overlay visibility state
                  onHide={() => {
                    setOverlayOpen(false); // Close the overlay when clicked outside
                  }}
                >
                  <button
                    className="btn-primary-update text-small"
                    onClick={() => setOverlayOpen(!isOverlayOpen)}
                  >
                    {startDate ? formatToDateString(startDate) : "Start Date"}
                    &nbsp;
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </OverlayTrigger>

                <span className="to-span mx-3">to</span>

                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={popoverEndDate}
                  show={isOverlayOpenEnd} // Pass the overlay visibility state
                  onHide={() => {
                    setOverlayOpenEnd(false); // Close the overlay when clicked outside
                  }}
                >
                  <button
                    className="btn-primary-update text-small"
                    onClick={() => setOverlayOpenEnd(!isOverlayOpenEnd)}
                  >
                    {endDate ? formatToDateString(endDate) : "End Date"}
                    &nbsp;
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </OverlayTrigger>
              </div>

              <h4 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  {/* Request History */}
                </span>
              </h4>
            </div>

            <div className="card-toolbar mt-3">
              <CSVLink
                data={modifiedData}
                filename={csvFilename}
                className="btn btn-sm btn-primary"
              >
                <span className="svg-icon svg-icon-2"></span>{" "}
                <i className="bi bi-download"></i>
                <span className="mx-2"> Export</span>
              </CSVLink>
            </div>
          </div>

          {/* start table */}
          <div className="table-responsive pt-3">
            <table className="table pt-5">
              <thead className="table-color pt-5">
                <tr>
                  <th className="min-w-30px" scope="col">
                    <div>Host Email/Display Name</div>
                  </th>
                  {/* <th className="min-w-30px text-center" scope="col">
                    Name
                  </th> */}
                  <th className="min-w-30px text-center" scope="col">
                    Date
                  </th>
                  {/* <th className="min-w-30px text-center" scope="col">
                    Time
                  </th> */}
                  <th className="text-nowrap min-w-30px " scope="col">
                    Storage Capacity(GB)
                  </th>
                  <th className="min-w-30px text-center" scope="col">
                    Group
                  </th>

                  <th className="min-w-30px text-center" scope="col">
                    Admin Email
                  </th>
                  <th className="text-nowrap min-w-30px" scope="col">
                    User Capacity(GB)
                  </th>
                  <th
                    className="min-w-30px text-center"
                    scope="col"
                    colSpan={3}
                  >
                    Action{" "}
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <td colSpan={7} className="text-center">
                  Loading...
                </td>
              ) : (
                <>
                  {currentUser && currentUser?.length > 0 ? (
                    currentUser.map((userDataa) => (
                      <tbody key={userDataa.host_email}>
                        <tr>
                          <td>
                            <div>{userDataa.host_email}</div>
                            <div>{userDataa.display_name}</div>
                          </td>
                          <td className="text-center">
                            {
                              formatDateTime(userDataa.created_datetime)
                                .formattedDate
                            }
                          </td>
                          {/* <td className="text-center">
                          {
                            formatDateTime(userDataa.created_datetime)
                              .formattedTime
                          }
                        </td> */}
                          <td className="text-center">
                            {userDataa.bucket_capacity_in_gb}
                          </td>
                          {/* <td>{userDataa.bucket_capacity_in_gb}</td> */}
                          <td className="text-center">{userDataa.group}</td>

                          <td className="text-center">{userDataa.parent_id}</td>
                          <td className="text-center">
                            {userDataa.user_capacity}
                          </td>
                          <td>
                            <Link
                              className={`update-icon icon-with-space`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Edit User Detail"
                              onClick={() =>
                                showSubmit(
                                  userDataa.host_email,

                                  userDataa.user_capacity,

                                  userDataa.is_account_active,
                                  userDataa.is_partner_admin,
                                  userDataa.bucket_capacity_in_gb,
                                  reset()
                                )
                              }
                            >
                              <i className="fa-sharp fa-solid fa-pen"></i>
                            </Link>
                          </td>
                          {/* <td>
                            <Link
                              className={`update-icon icon-with-space`}
                              onClick={() =>
                                showSubmit(
                                  userDataa.host_email,
                                  userDataa.user_capacity
                                )
                              }
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Update Capacity"
                            >
                              <i className="fa-solid fa-bucket"></i>
                            </Link>
                          </td> */}
                          <td>
                            <Link
                              className={`delete-icon icon-with-space`}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Delete User"
                              onClick={() => deleteUser(userDataa.host_email)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <>
                      <td colSpan={7} className="text-center">
                        No Data Available
                      </td>
                    </>
                  )}
                </>
              )}
            </table>
          </div>
          <div className="table-header pt-3 pb-3">
            <p className="text-lgt align-items-start flex-column">
              {/* <span className="card-label mb-1">
            Showing 1 to 10 of 50 entries
          </span> */}
            </p>

            {groupfilteredData ? (
              <div className="card-toolbar">
                {/* ... */}
                <ReactPaginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(groupfilteredData.length / postsPerPage)}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  containerClassName={"pagination"}
                  pageLinkClassName={"page-number"}
                  previousLinkClassName={"page-number"}
                  nextLinkClassName={"page-number"}
                  activeLinkClassName={"active"}
                />
              </div>
            ) : (
              <div className="loading">Loading..</div>
            )}
          </div>
          <div className="table-header pt-3 pb-3">
            <p className="text-lgt align-items-start flex-column"></p>
          </div>
        </div>
      </div>
      {/*Edit user detail Modal*/}

      <Modal show={showEditUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              variant="btn btn-sm btn-primary"
              onClick={handleCloseUserModal}
            >
              <h5>Edit User Detail</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-none px-4">
          <form>
            <div className="profile-form">
              <div className="mb-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  required
                  name="email"
                  value={currentemail}
                  readOnly
                />
              </div>
              {/* <div className="mb-3">
                <label htmlFor=="formGroupExampleInput" className="form-label">
                  Display Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  required
                  name="display_name"
                  value={currentDisplayName}
                  onChange={(e) => setCurrentDisplayName(e.target.value)}
                />
              </div> */}

              <div className="mb-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Storage Capacity(GB)
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  required
                  name="currentBucketCapacity"
                  {...register("currentBucketCapacity", {
                    required: true,
                    value: currentBucketCapacity,
                    onChange: (e) => setCurrentBucketCapacity(e.target.value),
                  })}
                  // disabled={currentBucketCapacity === "0"}
                  disabled={currentemailpayasyougo}
                />
                {errors.currentBucketCapacity && (
                  <span className="text-danger">
                    {errors.currentBucketCapacity.message}
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  User Capacity(GB)
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  required
                  name="currentCapacityValue"
                  // defaultValue={currentCapacityValue}
                  {...register("currentCapacityValue", {
                    value: currentCapacityValue,
                    required: true,
                    onChange: (e) => setCurrentCapacityValue(e.target.value),
                  })}
                  disabled={currentemailpayasyougo}
                />
                {errors.currentCapacityValue && (
                  <span className="text-danger">
                    {errors.currentCapacityValue.message}
                  </span>
                )}
              </div>

              {/* <div className="mb-3">
                <label htmlFor=="formGroupExampleInput" className="form-label">
                  User Capacity
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  required
                  name="user_capacity"
                  value={currentCapacityValue}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, "");
                    setCurrentCapacityValue(inputValue);
                  }}
                  disabled={currentCapacityValue === "0"}
                /> */}
              {/* {currentCapacityValue > currentBucketCapacity && (
                  <div className="text-danger">
                    "User Capacity cannot exceed Storage Capacity"
                  </div>
                )} */}
              {/* <div className="text-danger">
                  {currentCapacityValue > currentBucketCapacity &&
                    "User Capacity cannot exceed storage Capacity"}
                  {!currentCapacityValue && "User Capacity is required"}
                </div> */}
              {/* {errors.currentCapacityValue && (
                  <span className="text-danger">
                    {errors.currentCapacityValue.message}
                  </span>
                )}
              </div> */}

              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  name="is_group_admin"
                  checked={currentIsGroupAdmin}
                  onChange={(e) => setCurrentIsGroupAdmin(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Partner Admin
                </label>
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck2"
                  name="is_account_active"
                  checked={currentIsAccountActive}
                  onChange={(e) => setCurrentIsAccountActive(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="exampleCheck2">
                  Account Active
                </label>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-sm secondary" onClick={handleCloseUserModal}>
            Cancel
          </Button>
          <Button
            variant="btn btn-sm btn-primary"
            onClick={handleSubmit(handleEditSubmit)}
            disabled={!currentCapacityValue || !currentBucketCapacity}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <FooterComponent />
    </LayoutComponent>
  );
};

export default ViewAdminUser;
