import React, { useEffect, useContext } from "react";

import ExchangeApiContext from "../context/Exchangecontext/exchangeapicontext";
import SuperAdminChart from "./Super_Admin/SuperadminChart";
import Clientchart from "./Client_Admin/Clientchart";

const ChartPage = () => {
  const context = useContext(ExchangeApiContext);
  const { role, getexchangetoken } = context;

  useEffect(() => {
    getexchangetoken();
  });

  return (
    <>
      {role === "super_admin" ? (
        <SuperAdminChart />
      ) : (
        role === "client_admin" && <Clientchart />
      )}
    </>
  );
};

export default ChartPage;
