import React from "react";
import { NavLink } from "react-router-dom";

import $ from "jquery";
import { useContext } from "react";
import ExchangeApiContext from "../context/Exchangecontext/exchangeapicontext";
import { useState } from "react";
import { Dropdown } from "bootstrap";
const SidebarComponent = () => {
  const context = useContext(ExchangeApiContext);
  const { role } = context;
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const toggleSidebar = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  // const toggleSidebar = () => {
  //   if (isDropdownOpen) {
  //     setDropdownOpen(false);
  //   } else {
  //     setDropdownOpen(true);
  //   }
  // };
  $(document).ready(function () {
    $(".menu").click(function () {
      $(".sidebar").toggleClass("sidebar-visible");
    });
  });

  //  Begin::Responsive Sidebar
  $(document).ready(function () {
    $(".open-btn").on("click", function () {
      $(".sidebar").addClass("active");
    });

    $(".close-btn").on("click", function () {
      $(".sidebar").removeClass("active");
    });
  });

  // Begin::Responsive Sidebar

  return (
    <>
      {/* <button className="menu" onClick={toggleSidebar}>
        <i className="bi bi-list"></i>
      </button> */}

      <button className="container btn px-1 py-3 open-btn d-md-none d-block">
        <i className="bi bi-list"></i>
      </button>
      {!isDropdownOpen && (
        <div className="sidebar" id="side_nav">
          <div className="header-box px-2 py-2">
            <button className="btn d-md-none d-block close-btn px-1 py-0">
              <i className="bi bi-x"></i>
            </button>

            <div className="sidehead-text text-center pb-4"></div>
          </div>

          <div>
            {role === "super_admin" ? (
              <div>
                <NavLink
                  to="/create-admin"
                  className="text-decoration-none sidebr"
                >
                  <span className="sidebar-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-plus-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fillRule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </span>
                  Create Admin
                </NavLink>
                <NavLink
                  to="/create-user"
                  className="text-decoration-none sidebr"
                >
                  <span className="sidebar-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-plus-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fillRule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </span>
                  Create User
                </NavLink>
              </div>
            ) : (
              role === "client_admin" && (
                <NavLink to="/create" className="text-decoration-none sidebr">
                  <span className="sidebar-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-plus-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      <path
                        fillRule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </span>
                  Create
                </NavLink>
              )
            )}
            <NavLink to="/" className="text-decoration-none sidebr">
              <span className="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-people-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>
              </span>
              View
            </NavLink>

            <NavLink to="/chart" className="text-decoration-none sidebr">
              <span className="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                </svg>
              </span>
              Chart
            </NavLink>
            <NavLink to="/log" className="text-decoration-none sidebr">
              <span className="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-file-earmark-text-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z" />
                </svg>
              </span>
              Logs
            </NavLink>
          </div>
        </div>
      )}
      <button className="menu" onClick={toggleSidebar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
    </>
  );
};

export default SidebarComponent;
