import React, { useState, useEffect } from "react";
import LayoutComponent from "../../shared/LayoutComponent";
import { useContext } from "react";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import api from "../../api";
import { CSVLink } from "react-csv";
import { Calendar } from "react-date-range";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import Dropdown from "react-bootstrap/Dropdown";
import { formatToDateString } from "../../utils/date.utils";
import { toast } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";

const SuperAdminLogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [postsPerPage] = useState(7);
  const context = useContext(ExchangeApiContext);
  const [sortedLogData, setSortedLogData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [orgfilteredData, setOrgFilteredData] = useState([]);
  const [crudfilteredData, setCrudFilteredData] = useState([]);
  const [selectedOrg, setselectedOrg] = useState("Organization");
  const [selectedCrud, setSelectedCrud] = useState("CRUD");

  const { loggedin_user_email } = context;
  const params = {
    user: loggedin_user_email,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/logs", {
          params,
        });

        setData(response.data);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [loggedin_user_email]);

  const formatData = () => {
    return data.map((item, index) => {
      const formattedLogs = item.org_logs.map((log) => {
        const regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d+)/;
        const [events, dateTime] = log.split(regex);
        const formattedEvents = events.replace(": Done in", "");
        let crudOperation;
        if (events.includes("created")) {
          crudOperation = "Created";
        } else if (events.includes("Deleted")) {
          crudOperation = "Deleted";
        } else if (events.includes("Updated")) {
          crudOperation = "Updated";
        } else if (events.includes("read")) {
          crudOperation = "Read";
        } else {
          crudOperation = "Unknown";
        } // Remove " Done in" from the events string
        const formattedDate = moment(dateTime, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );

        const formattedTime = moment(
          dateTime,
          "YYYY-MM-DD HH:mm:ss.SSSSSS"
        ).format("YYYY-MM-DD HH:mm:ss");
        return {
          dates: formattedDate,
          times: formattedTime,
          events: formattedEvents,
          crudOperation: crudOperation,
        };
      }).filter(log => log.crudOperation !== "Unknown"); // Filter out logs with Unknown crudOperation

      return {
        key: index,
        org: item.org,
        org_log: formattedLogs,
      };
    });
  };

  function formatTime(times) {
    const dateObj = new Date(times);
    const formattedTime = dateObj.toTimeString().split(" ")[0];
    return formattedTime;
  }

  useEffect(() => {
    const formattedData = formatData();
    const LogData = formattedData.flatMap(({ key, org, org_log }) =>
      org_log.map(({ dates, events, times, crudOperation }) => ({
        key: key + "-" + dates,
        org,
        dates,
        times,
        events,
        crudOperation,
      }))
    );
    const sortedData = LogData.sort((a, b) => {
      return new Date(b.dates) - new Date(a.dates);
    });
    setSortedLogData(sortedData);
  }, [data]);

  const getFilteredData = ({ startDate, endDate, sortedLogData }) => {
    if (!sortedLogData || sortedLogData.length <= 0) return [];

    if (startDate && endDate && startDate > endDate) {
      console.log("Start date cannot be greater than end date");
      return [];
    }

    const formattedStartDate = startDate
      ? new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )
      : null;

    const formattedEndDate = endDate
      ? new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59
        )
      : null;
    // console.log("formattedend", formattedEndDate);

    const filteredData = sortedLogData.filter((item) => {
      const date = new Date(item["dates"]);

      return (
        (!formattedStartDate || date >= formattedStartDate) &&
        (!formattedEndDate || date <= formattedEndDate)
      );
    });

    return filteredData;
  };

  const handleStartDate = (date) => {
    if (date) {
      setStartDate(date);
      const data = getFilteredData({
        startDate: date,
        endDate,
        sortedLogData,
      });
      setFilteredData(data);
      setOverlayOpen(false);
    } else {
      console.log("Date is not available");
    }
  };

  const handleEndDate = (date) => {
    if (date) {
      console.log("End Date is available:", date);
      setEndDate(date);
      const data = getFilteredData({
        startDate,
        endDate: date,
        sortedLogData,
      });
      setFilteredData(data);
      setOverlayOpenEnd(false);
    } else {
      console.log("Date is not available");
    }
  };

  useEffect(() => {
    const datass = getFilteredData({ startDate, endDate, sortedLogData });
    setFilteredData(datass);
  }, [startDate, endDate, sortedLogData]);

  const popoverStartDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => handleStartDate(date)}
        date={startDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );

  const popoverEndDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => handleEndDate(date)}
        date={endDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );

  const uniqueGroupNames = [...new Set(sortedLogData.map((item) => item.org))];

  const uniquecrudNames = [
    ...new Set(sortedLogData.map((item) => item.crudOperation)),
  ];

  useEffect(() => {
    if (selectedOrg === "Organization") {
      setOrgFilteredData(filteredData);
    } else {
      const orgfilteredData = filteredData.filter(
        (filterData) => filterData.org === selectedOrg
      );
      setOrgFilteredData(orgfilteredData);
      setCurrentPage(1);
    }
  }, [selectedOrg, filteredData]);

  useEffect(() => {
    if (selectedCrud === "CRUD") {
      setCrudFilteredData(orgfilteredData);
    } else {
      const crudfilteredData = orgfilteredData.filter(
        (filterData) => filterData.crudOperation === selectedCrud
      );
      setCrudFilteredData(crudfilteredData);
      setCurrentPage(1);
    }
  }, [selectedCrud, orgfilteredData]);

  const handleOrgFilterChange = (org) => {
    setselectedOrg(org);
    setCurrentPage(1);
  };

  const handleCrudFilterChange = (crudOperation) => {
    setSelectedCrud(crudOperation);
    setCurrentPage(1);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = crudfilteredData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(crudfilteredData.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const csvFilename = "UserLogs.csv";
  const modifiedData = filteredData.map(({ events, dates, times, org }) => {
    return {
      Organization: org,
      Date: dates,
      Time: moment
        .tz(formatTime(times), "HH:mm:ss.SSSSSS", "GMT")
        .tz("Asia/Kathmandu")
        .format("hh:mm A"),
      Event: events,
    };
  });
  // const formattedData = modifiedData.map(({ Date, Time, Event }) => ({
  //   Date: moment(Date).format("DD/MM/YYYY"),
  //   Time,
  //   Event,
  // }));
  // console.log(formattedData);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [isOverlayOpenEnd, setOverlayOpenEnd] = useState(false);
  return (
    <LayoutComponent>
      <div className="table-content pt-5">
        <div className="container">
          {/* <div className="table-header pb-1"> */}
          {/* <div className="d-flex align-items-center">
              <div className="mb-3">
                {" "}
                <div className="date-container">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverStartDate}
                    rootClose
                    rootCloseEvent="mousedown"
                    show={isOverlayOpen} // Pass the overlay visibility state
                    onHide={() => {
                      setOverlayOpen(false); // Close the overlay when clicked outside
                    }}
                  >
                    <button
                      className="btn-primary-update "
                      onClick={() => setOverlayOpen(!isOverlayOpen)}
                    >
                      {startDate
                        ? formatToDateString(startDate)
                        : "Select Start Date"}
                      &nbsp;
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </OverlayTrigger>

                  <span className="to-span mx-1">to</span>

                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={popoverEndDate}
                    show={isOverlayOpenEnd} // Pass the overlay visibility state
                    onHide={() => {
                      setOverlayOpenEnd(false); // Close the overlay when clicked outside
                    }}
                  >
                    <button
                      className="btn-primary-update "
                      onClick={() => setOverlayOpenEnd(!isOverlayOpenEnd)}
                    >
                      {endDate
                        ? formatToDateString(endDate)
                        : "Select End Date"}
                      &nbsp;
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
              
            </div>
            <div className="card-toolbar">
              <CSVLink
                data={modifiedData}
                filename={csvFilename}
                className="btn btn-sm btn-primary"
              >
                <span className="svg-icon svg-icon-2"></span>{" "}
                <i className="bi bi-download"></i>
                <span className="mx-1"> Export</span>
              </CSVLink>
            </div>
          </div>
          <div className="table-header">
            <div className="d-flex align-items-center">
              <div className="card-toolbar">
                <Dropdown>
                  <span className="to-span me-2">Organization :</span>
                  <Dropdown.Toggle className="group-filter" variant="outline">
                    <i className="bi bi-filter"></i>
                    <span className="mx-1"> {selectedOrg}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-text-size">
                    <Dropdown.Item onClick={() => handleOrgFilterChange("All")}>
                      All
                    </Dropdown.Item>
                    {uniqueGroupNames.map((org) => (
                      <Dropdown.Item
                        key={org}
                        onClick={() => handleOrgFilterChange(org)}
                      >
                        {org}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-title align-items-start flex-column my-2">
                <Dropdown>
                  <span className="to-span me-2">CRUD :</span>
                  <Dropdown.Toggle className="group-filter" variant="outline">
                    <i className="bi bi-filter"></i>
                    <span className="mx-1"> {selectedCrud} </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-text-size">
                    <Dropdown.Item
                      onClick={() => handleCrudFilterChange("All")}
                    >
                      All
                    </Dropdown.Item>
                    {uniquecrudNames.map((crudOperation) => (
                      <Dropdown.Item
                        key={crudOperation}
                        onClick={() => handleCrudFilterChange(crudOperation)}
                      >
                        {crudOperation}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div> */}
          <div>
            <h6>Logs list</h6>
            <p className="to-span me-2 mb-4 mt-1">
              The logs list are listed below.
            </p>
          </div>
          <div className="table-header pb-1">
            <div className="d-flex ">
              <Dropdown>
                {/* <span className="to-span me-2">Organization :</span> */}
                <Dropdown.Toggle className="group-filter" variant="outline">
                  <i className="bi bi-filter"></i>
                  <span className="mx-1"> {selectedOrg}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-text-size">
                  <Dropdown.Item
                    onClick={() => handleOrgFilterChange("Organization")}
                  >
                    Organization
                  </Dropdown.Item>
                  {uniqueGroupNames.map((org) => (
                    <Dropdown.Item
                      key={org}
                      onClick={() => handleOrgFilterChange(org)}
                    >
                      {org}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                {/* <span className="to-span me-2">CRUD :</span> */}
                <Dropdown.Toggle className="group-filter" variant="outline">
                  <i className="bi bi-filter"></i>
                  <span className="mx-1"> {selectedCrud} </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-text-size">
                  <Dropdown.Item onClick={() => handleCrudFilterChange("CRUD")}>
                    CRUD
                  </Dropdown.Item>
                  {uniquecrudNames.map((crudOperation) => (
                    <Dropdown.Item
                      key={crudOperation}
                      onClick={() => handleCrudFilterChange(crudOperation)}
                    >
                      {crudOperation}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <div className="date-container pb-1">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popoverStartDate}
                  rootClose
                  rootCloseEvent="mousedown"
                  show={isOverlayOpen} // Pass the overlay visibility state
                  onHide={() => {
                    setOverlayOpen(false); // Close the overlay when clicked outside
                  }}
                >
                  <button
                    className="btn-primary-update text-small"
                    onClick={() => setOverlayOpen(!isOverlayOpen)}
                  >
                    {startDate ? formatToDateString(startDate) : "Start Date"}
                    &nbsp;
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </OverlayTrigger>

                <span className="to-span mx-3">to</span>

                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={popoverEndDate}
                  show={isOverlayOpenEnd} // Pass the overlay visibility state
                  onHide={() => {
                    setOverlayOpenEnd(false); // Close the overlay when clicked outside
                  }}
                >
                  <button
                    className="btn-primary-update text-small"
                    onClick={() => setOverlayOpenEnd(!isOverlayOpenEnd)}
                  >
                    {endDate ? formatToDateString(endDate) : "End Date"}
                    &nbsp;
                    <i className="bi bi-chevron-down"></i>
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div>
              <CSVLink
                data={modifiedData}
                filename={csvFilename}
                className="btn btn-sm btn-primary"
              >
                <span className="svg-icon svg-icon-2"></span>{" "}
                <i className="bi bi-download"></i>
                <span className="mx-1"> Export</span>
              </CSVLink>
            </div>
          </div>

          <div className="table-responsive pt-3">
            {/* Organization filter */}

            <table className="table table-sm pt-3 table-log table-smaller">
              <thead className="log-table-color">
                <tr>
                  <th className="min-w-10px" scope="col">
                    Organization
                  </th>
                  <th className="min-w-10px" scope="col">
                    Date
                  </th>
                  <th className="min-w-10px" scope="col">
                    Time
                  </th>
                  <th className="min-w-10px" scope="col">
                    Events
                  </th>
                  {/* <th className="min-w-10px" scope="col">
                    CRUD
                  </th> */}
                </tr>
              </thead>
              {isLoading ? (
                <td colSpan={4} className="text-center">Loading...</td>
              ) : (
                <tbody>
                  {currentPosts && currentPosts?.length > 0 ? (
                    currentPosts.map(
                      (
                        { key, org, dates, events, times, crudOperation },
                        index
                      ) => (
                        <tr
                          key={`${key}-${dates}-${index}`}
                          className="log-table-padding"
                        >
                          <td className="text-nowrap">{org}</td>
                          <td className="text-nowrap">{dates}</td>
                          <td className="text-nowrap">
                            {moment
                              .tz(formatTime(times), "HH:mm:ss.SSSSSS", "GMT")
                              .tz("Asia/Kathmandu")
                              .format("hh:mm A")}
                          </td>
                          <td>{events}</td>
                          {/* <td>{crudOperation}</td> */}
                        </tr>
                      )
                    )
                  ) : (
                    <> <td colSpan={4} className="text-center">No Data Available </td></>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="table-header pt-3 pb-3">
            <p className="text-lgt align-items-start flex-column">
              {/* <span className="card-label mb-1">
            Showing 1 to 10 of 50 entries
          </span> */}
            </p>
            {crudfilteredData ? (
              <div className="card-toolbar">
                {/* ... */}
                <ReactPaginate
                  onPageChange={paginate}
                  pageCount={Math.ceil(crudfilteredData.length / postsPerPage)}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  containerClassName={"pagination"}
                  pageLinkClassName={"page-number"}
                  previousLinkClassName={"page-number"}
                  nextLinkClassName={"page-number"}
                  activeLinkClassName={"active"}
                  forcePage={currentPage > 0 ? currentPage - 1 : 0} // Ensure the forcePage prop is not greater than the maximum page index
                  initialPage={0} // Set the initial page index to 0
                  disableInitialCallback={true}
                />
              </div>
            ) : (
              <div className="loading">Loading..</div>
            )}
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default SuperAdminLogs;
