import React from "react";

import SidebarComponent from "./SidebarComponent";

const LayoutComponent = ({ children }) => {
  return (
    <>
      <div className="d-flex " id="wrapper">
        <SidebarComponent />
        <div id="page-content-wrapper" className="flex-grow-1">
          <div className="container-fluid overflow-auto">{children}</div>
        </div>
      </div>
    </>
  );
};

export default LayoutComponent;
