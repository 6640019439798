import logo from "./logo.svg";
import "./App.css";
import "./Styles/sidebar.scss";
import "./Styles/space.scss";
import "./Styles/variable.scss";
import "./Styles/pagination.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import { QueryClient, QueryClientProvider } from "react-query";

import Routers from "./routers";
import "./Styles/style.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import apiService from "./service/service";
import { useEffect, useState } from "react";
import ExchangeApiState from "./context/Exchangecontext/exchangeapistate";
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ExchangeApiState>
          <Routers />
        </ExchangeApiState>
        <ToastContainer />
        <Tooltip id="my-tooltip" />
      </QueryClientProvider>
    </>
  );
}

export default App;
