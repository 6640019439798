import { useState } from "react";
import exchangeapiService from "../../service/service";
import ExchangeApiContext from "./exchangeapicontext";
import axios from "axios";

const ExchangeApiState = (props) => {
  const [role, setRole] = useState();
  const [exp_time, setExpireTime] = useState();
  const [loggedin_user_email, setLoggedinUserEmail] = useState();

  const getexchangetoken = () => {
    exchangeapiService
      .exchangeToken()
      .then((response) => {
        // console.log(response);
        setRole(response.data.user_data.role);
        setLoggedinUserEmail(response.data.user_data.body);
        setExpireTime(response.data.exp_time);
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = exp_time - currentTime;

        if (remainingTime > 0) {
          // console.log("remainingTime", remainingTime);
          const timeoutId = setTimeout(() => {
            // exchangeapiService.exchangeToken().then(() => {
            //   window.top.location.href =
            //     "http://52.90.100.226/nextcloud/index.php/apps/dashboard/";
            // });
            window.top.location.href =
              "https://gcss.genesesolution.com/index.php/login";
          }, remainingTime * 1000);

          return () => {
            clearTimeout(timeoutId);
          };
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <ExchangeApiContext.Provider
      value={{
        role,
        setRole,
        exp_time,
        setExpireTime,
        loggedin_user_email,
        setLoggedinUserEmail,
        getexchangetoken,
      }}
    >
      {props.children}
    </ExchangeApiContext.Provider>
  );
};
export default ExchangeApiState;
