import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import * as yup from "yup";
import api from "../../api";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import UploadCSVFileModal from "./uploadcsvfilemodal";
import LayoutComponent from "../../shared/LayoutComponent";

const CreateNormalUser = () => {
  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [display_name, setDisplayName] = useState();
  const [loggedemail, setLoggedEmail] = useState(loggedin_user_email);
  const [user_capacity, setUserCapacity] = useState();
  const [is_group_admin, setISGroupAdmin] = useState(false);
  const [parent_id, setparent_id] = useState();
  const [payasyougo, setPayAsYouGo] = useState();
  const [selectedpatneradminEmail, setselectedpatneradminEmail] =
    useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [checks, setChecks] = useState({
  //   capsLetterCheck: false,
  //   numberCheck: false,
  //   pwdLengthCheck: false,
  //   specialCharCheck: false,
  // });
  const [error, setError] = useState();

  const handleAdminCheckboxChange = (event) => {
    setISGroupAdmin(event.target.checked);
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    display_name: yup.string().required("Display Name is required"),
    parent_id: yup.string().required("Patner Email is required"),
    user_capacity: yup
      .string()
      .required("User Capacity is required")
      .matches(/^(0|[1-9]\d*)$/, "User capacity must be a non-negative number"),

    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
    //   ),

    password: yup
      .string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must include at least one uppercase letter")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "Password must include at least one lowercase letter")
      .matches(/[0-9]/, "Password must include at least one digit")
      .matches(
        /[^A-Za-z0-9]/,
        "Password must include at least one special character"
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      display_name: "",
      user_capacity: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
    shouldUnregister: false,
  });

  const params = {
    user: loggedin_user_email,
  };

  useQuery("data", async () => {
    try {
      const response = await api.get(`/view`, {
        params,
      });
      // console.log(response.data);
      const UserData = JSON.parse(response.data.body);
      // console.log("UserData", UserData);
      setData(UserData);
    } catch (error) {
      console.error(error);
    }
  });

  const uniquepatneradminEmail = [
    ...new Set(
      data
        .filter(
          (patneradminEmail) => patneradminEmail.is_partner_admin === true
        )
        .map((patneradminEmail) => patneradminEmail.parent_id)
    ),
  ];

  useEffect(() => {
    if (selectedpatneradminEmail) {
      const selectedPayAsYouGO = data.find(
        (patneradminEmail) =>
          patneradminEmail.parent_id === selectedpatneradminEmail
      );
      setPayAsYouGo(selectedPayAsYouGO?.is_pay_as_you_go || false);

      if (selectedPayAsYouGO?.is_pay_as_you_go) {
        if (getValues("user_capacity") !== "0") {
          setValue("user_capacity", "0", {
            shouldValidate: true,
          });
        }
      } else {
        if (getValues("user_capacity") !== "") {
          setValue("user_capacity", "", {
            shouldValidate: true,
          });
        }
      }
    }
  }, [selectedpatneradminEmail, data]);

  const options = uniquepatneradminEmail.map((patneradminEmail) => ({
    value: patneradminEmail,
    label: patneradminEmail,
  }));

  // const handleOnChange = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);
  //   setChecks({
  //     capsLetterCheck: /[A-Z]/.test(value),
  //     numberCheck: /[0-9]/.test(value),
  //     pwdLengthCheck: value.length >= 8,
  //     specialCharCheck: /[!@#$%^&*(),.?":{}|<>]/.test(value),
  //   });
  // };

  const handleFormSubmit = async () => {
    setLoading(true);
    const forminputdata = {
      email: email,
      password: password,
      display_name: display_name,
      // admin_e: loggedemail,
      user_capacity: payasyougo ? 0 : user_capacity,
      admin_email: getValues("parent_id"),
      is_group_admin: is_group_admin ? true : false,
    };

    const params = {
      user: loggedemail,
    };

    api
      .post("/create-normal-users", forminputdata, {
        params,
      })
      .then(function (response) {
        setEmail("");
        setPassword("");
        setDisplayName("");
        setLoggedEmail("");
        setUserCapacity("");
        setparent_id("");
        setISGroupAdmin("");
        toast.success("User created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/");
        // console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <LayoutComponent>
        <div className="table-content pt-5" style={{ minHeight: "87vh" }}>
          {/* <div className="content py-3" style={{ minHeight: "87vh" }}> */}
          <div className="container">
            {/* <h4 className="card-title align-items-start flex-column pb-2">
              <span className="card-label fw-bold fs-3 mb-1">
                Create Admin User
              </span>
            </h4> */}

            <div
              className="profile-form w-75 w-100-mob"
              style={{ flexGrow: 1 }}
            >
              <div className="table-header pb-3">
                <div className="d-flex align-items-center">
                  <h6 className="card-title align-items-start flex-column pb-4 pt-4">
                    <span className="pt-2"> Create User</span>
                  </h6>
                </div>
                <div className="card-toolbar">
                  <UploadCSVFileModal />
                </div>
              </div>

              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  ></button>
                </div>
              )}
              <div className="row">
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control bg-transparent"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                      onChange: (e) => setEmail(e.target.value),
                    })}
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control bg-transparent"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Password must contain at least 8 characters,
    one uppercase letter,
    one lowercase letter,
    one number, and
    one special character"
                    {...register("password", {
                      required: true,

                      onChange: (e) => setPassword(e.target.value),
                    })}
                  />
                  {errors.password && (
                    <span className="text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Display Name
                  </label>
                  <input
                    type="text"
                    className="form-control bg-transparent"
                    {...register("display_name", {
                      required: true,

                      onChange: (e) => setDisplayName(e.target.value),
                    })}
                  />
                  {errors.display_name && (
                    <span className="text-danger">
                      {errors.display_name.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    User Capacity(GB)
                  </label>
                  {/* <input
                  type="text"
                  className="form-control bg-transparent"
                  {...register("user_capacity", {
                    required: true,

                    onChange: (e) => setUserCapacity(e.target.value),
                  })}
                /> */}

                  <input
                    type="text"
                    className="form-control bg-transparent"
                    {...register("user_capacity", {
                      required: true,
                      onChange: (e) => setUserCapacity(e.target.value),
                    })}
                    // value={payasyougo ? "0" : user_capacity || ""}
                    readOnly={payasyougo}
                  />
                  {errors.user_capacity && (
                    <span className="text-danger">
                      {errors.user_capacity.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Logged In Email
                  </label>
                  <input
                    type="text"
                    className="form-control bg-transparent"
                    value={loggedin_user_email}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pb-2">
                  {" "}
                  <label htmlFor="searchType">Partner Admin Email</label>
                  {/* <Select
                  id="searchType"
                  name="searchType"
                  options={options}
                  {...register("parent_id", { required: true })}
                  onChange={(selectedOption) =>
                    setValue("parent_id", selectedOption.value)
                  }
                /> */}
                  <Select
                    id="searchType"
                    name="searchType"
                    options={options}
                    {...register("parent_id", { required: true })}
                    onChange={(selectedOption) => {
                      setValue("parent_id", selectedOption.value);
                      setselectedpatneradminEmail(selectedOption.value);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 pb-2">
                  <div className="form-rem pt-3">
                    <div className=" form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={is_group_admin}
                        onChange={handleAdminCheckboxChange}
                      />

                      <label
                        className="form-check-label "
                        htmlFor="exampleCheck1"
                      >
                        Admin
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 py-2 d-grid gap-2">
                {!loading && (
                  <button
                    type="submit"
                    onClick={handleSubmit(handleFormSubmit)}
                    className="btn btn-sm btn-primary mt-4 button-width"
                  >
                    Submit
                  </button>
                )}

                {loading && (
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 button-width"
                    disabled
                  >
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};

export default CreateNormalUser;
