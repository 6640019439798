import React, { useState, useContext } from "react";

import { useQuery, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import api from "../../api";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import FooterComponent from "../../shared/FooterComponent";
import LayoutComponent from "../../shared/LayoutComponent";
import moment from "moment";
import { Calendar } from "react-date-range";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useEffect } from "react";
import { formatToDateString } from "../../utils/date.utils";

const ViewUser = () => {
  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;
  const queryClient = useQueryClient();
  const csvFilename = "myUserData.csv";

  const [postsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [errorShown, setErrorShown] = useState(false);

  const [globalEditData, setGlobalEditData] = useState({
    host_email: "",
    parent_id: "",
  });
  const [currentCapacityValue, setCurrentCapacityValue] = useState();
  const [currentemail, setCurrentemail] = useState();
  const [currentDisplayName, setCurrentDisplayName] = useState();
  const [currentIsGroupAdmin, setCurrentIsGroupAdmin] = useState(false);
  const [currentIsAccountActive, setCurrentIsAccountActive] = useState();
  const [newCapacityValue, setNewCapacityValue] = useState();
  const [userdata, setuserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [adminEmailPayAsYouGo, setAdminEmailPayAsYouGo] = useState();
  const [currentBucketCapacity, setCurrentBucketCapacity] = useState();
  // modal
  const [showUpdateCapacityModal, setShowUpdateCapacityModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [isOverlayOpenEnd, setOverlayOpenEnd] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const schema = yup.object().shape({
    currentBucketCapacity: yup
      .string()
      .required("storage Capacity is required")
      .matches(
        /^(0|[1-9]\d*)$/,
        "Storage capacity must be a non-negative number"
      ),

    currentCapacityValue: yup
      .string()
      .required("User Capacity is required")
      .matches(/^(0|[1-9]\d*)$/, "User capacity must be a non-negative number")
      .test(
        "lessThanBucketCapacity",
        "User capacity should be less than storage capacity",
        function (value) {
          const bucketCapacity = parseInt(this.parent.currentBucketCapacity);
          const userCapacity = parseInt(value);
          return userCapacity <= bucketCapacity;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: {
      currentBucketCapacity: currentBucketCapacity,
      currentCapacityValue: currentCapacityValue,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const handleCloseUserModal = () => {
    setShowEditUserModal(false);
    reset();
  };

  const params = {
    user: loggedin_user_email,
  };
  useQuery("userData", async () => {
    try {
      const response = await api.get(`/view`, {
        params,
      });
      const clientAdminData = JSON.parse(response.data.body);
      setuserData(clientAdminData);

      const adminemailpayasyougo = clientAdminData.some(
        (admin) => admin.is_pay_as_you_go
      );
      setAdminEmailPayAsYouGo(adminemailpayasyougo);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  });

  const formatDateTime = (datetime) => {
    const formattedDate = moment(datetime).format("DD/MM/YYYY");
    const formattedTime = moment(datetime)
      .tz("Asia/Kathmandu")
      .format("hh:mm A");
    return { formattedDate, formattedTime };
  };

  const getFilteredData = ({ startDate, endDate, userdata }) => {
    if (!userdata || userdata.length <= 0) return [];

    if (startDate && endDate && startDate > endDate) {
      toast.error("Start date cannot be greater than end date");
      return [];
    }

    const formattedStartDate = startDate
      ? new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )
      : null;

    const formattedEndDate = endDate
      ? new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59,
          999 // Set the milliseconds to 999 to consider local time
        )
      : null;

    const filteredData = userdata.filter((item) => {
      const date = new Date(item["created_datetime"]);

      // Check if the date is valid

      return (
        (!formattedStartDate || date >= formattedStartDate) &&
        (!formattedEndDate || date <= formattedEndDate)
      );
    });

    return filteredData;
  };

  const handleStartDate = (date) => {
    if (date) {
      setStartDate(date);
      if (endDate && date > endDate) {
        toast.error("Start date cannot be greater than end date");
      } else {
        const data = getFilteredData({
          startDate: date,
          endDate,
          userdata,
        });
        setFilteredData(data);
        setOverlayOpen(false);
      }
    } else {
      console.log("Date is not available");
    }
  };

  const handleEndDate = (date) => {
    if (date) {
      console.log("End Date is available:", date);
      setEndDate(date);
      const data = getFilteredData({
        startDate,
        endDate: date,
        userdata,
      });
      setFilteredData(data);
      setOverlayOpenEnd(false);
    } else {
      console.log("Date is not available");
    }
  };

  useEffect(() => {
    const dataa = getFilteredData({ startDate, endDate, userdata });
    setFilteredData(dataa);
  }, [startDate, endDate, userdata]);

  const popoverStartDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => {
          handleStartDate(date); // Close the popover when a date is selected
        }}
        date={startDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );

  const popoverEndDate = (
    <Popover style={{ minWidth: "300px" }}>
      <Calendar
        onChange={(date) => handleEndDate(date)}
        date={endDate}
        style={{ width: "100%" }}
      />
    </Popover>
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentUser = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const showSubmit = (
    host_email,
    bucket_capacity_in_gb,
    user_capacity,

    is_account_active,
    is_partner_admin
  ) => {
    // setGlobalEditData({
    //   host_email: host_email,
    //   // parent_id: loggedInEmail,
    // });
    setCurrentBucketCapacity(bucket_capacity_in_gb);
    setCurrentCapacityValue(user_capacity);
    setCurrentemail(host_email);
    setCurrentIsAccountActive(is_account_active);
    setCurrentIsGroupAdmin(is_partner_admin);

    setShowEditUserModal(true);
  };

  const handleUpdateSubmit = () => {
    const postData = {
      email: currentemail,
      display_name: "test",
      is_group_admin: currentIsGroupAdmin,
      bucket_capacity_in_gb: currentBucketCapacity,
      user_capacity: currentCapacityValue,

      is_account_active: currentIsAccountActive,
    };

    const param1 = {
      loggedInUser: loggedin_user_email,
    };
    api
      .put(`/update-user/${currentemail}`, postData, {
        params: param1,
      })
      .then(function (response) {
        toast.success("Update successful!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        queryClient.invalidateQueries("userData");

        setShowEditUserModal(false); // close modal after successful submission
        reset();
      })
      .catch(function (error) {
        toast.error("Failed to update user detail!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.log(error);
      });
  };

  const deleteUser = (host_email) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#10485aff",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete("/delete-user", {
            data: {
              admin_email: loggedin_user_email,
              delete_email: host_email,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "User has been deleted.", "success");

            queryClient.invalidateQueries("userData");
          })
          .catch((error) => {
            // Handle error
          });
      }
    });
  };

  const modifiedData = userdata.map((item) => {
    return {
      "Storage Capacity (GB)": item.bucket_capacity_in_gb,
      "Bucket Name": item.bucket_name,
      "Display Name": item.display_name,
      Group: item.group,
      Email: item.host_email,
      "Is Account Active ": item.is_account_active,
      "Is Partner Admin": item.is_partner_admin,
      "Is Pay As You GO": item.is_pay_as_you_go,
      "Is Super Admin": item.is_super_admin,
      "Parent Id": item.parent_id,
      "User Capacity": item.user_capacity,
    };
  });

  const handleSearch = () => {
    // Check if userdata is defined
    if (!userdata) {
      return;
    }

    // Filter the data based on the search term
    const filteredData = userdata.filter((user) =>
      user.host_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Use the filtered data for further processing
    // ...
    setFilteredData(filteredData);

    // Reset filteredGroupData if search term is empty
    if (searchTerm === "") {
      setSearchTerm("");
      setFilteredData(userdata);
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setFilteredData(userdata);
  };

  return (
    <div>
      <LayoutComponent>
        <div className="table-content pt-5">
          <div className="container">
            <div className="table-header pt-5">
              {/* <h4 className="card-title align-items-start flex-column"> */}
              {/* <span className="card-label fw-bold fs-3 mb-1"> */}
              {/* Request History */}
              <div className="search-date-container">
                <div className="d-flex align-items-center">
                  <div className="search-container">
                    <input
                      type="search"
                      id="form1"
                      className="form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyUp={handleSearch}
                      onClick={handleReset}
                    />

                    {/* <button
                type="button"
                className="search-button"
                onClick={handleSearch}
              >
                <i className="fas fa-search"></i>
              </button> */}
                  </div>
                  <div className="date-container">
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={popoverStartDate}
                      rootClose
                      rootCloseEvent="mousedown"
                      show={isOverlayOpen} // Pass the overlay visibility state
                      onHide={() => {
                        setOverlayOpen(false); // Close the overlay when clicked outside
                      }}
                    >
                      <button
                        className="btn-primary-update text-small"
                        onClick={() => setOverlayOpen(!isOverlayOpen)}
                      >
                        {startDate
                          ? formatToDateString(startDate)
                          : "Start Date"}
                        &nbsp;
                        <i className="bi bi-chevron-down"></i>
                      </button>
                    </OverlayTrigger>

                    <span className="to-span mx-3">to</span>

                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={popoverEndDate}
                      show={isOverlayOpenEnd} // Pass the overlay visibility state
                      onHide={() => {
                        setOverlayOpenEnd(false); // Close the overlay when clicked outside
                      }}
                    >
                      <button
                        className="btn-primary-update text-small"
                        onClick={() => setOverlayOpenEnd(!isOverlayOpenEnd)}
                      >
                        {endDate ? formatToDateString(endDate) : "End Date"}
                        &nbsp;
                        <i className="bi bi-chevron-down"></i>
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

              {/* </span> */}
              {/* </h4> */}

              <div className="card-toolbar">
                <CSVLink
                  data={modifiedData}
                  filename={csvFilename}
                  className="btn btn-sm btn-primary"
                >
                  <span className="svg-icon svg-icon-2"></span>{" "}
                  <i className="bi bi-download"></i>
                  <span className="mx-2"> Export</span>
                </CSVLink>
              </div>
            </div>
            {/* start table */}
            <div className="table-responsive pt-3">
              <table className="table pt-5">
                <thead className="table-color pt-5">
                  <tr>
                    <th className="min-w-30px" scope="col">
                      Host Email
                    </th>
                    <th
                      className="text-nowrap min-w-30px text-center"
                      scope="col"
                    >
                      Date
                    </th>
                    <th className="text-nowrap min-w-30px " scope="col">
                      Storage Capacity(GB)
                    </th>
                    <th className="min-w-30px text-center" scope="col">
                      Group
                    </th>

                    <th className="min-w-30px" scope="col">
                      Admin Email
                    </th>
                    <th className="text-nowrap min-w-30px" scope="col">
                      User Capacity(GB)
                    </th>
                    <th
                      className="min-w-30px text-center"
                      scope="col"
                      colSpan={3}
                    >
                      Action{" "}
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                   <td colSpan={7} className="text-center">Loading...</td>
                ) : (
                  <>
                    {currentUser && currentUser?.length > 0 ? (
                      currentUser.map((userDataa) => (
                        <tbody key={userDataa.host_email}>
                          <tr>
                          <td>
                            <div>{userDataa.host_email}</div>
                            <div>{userDataa.display_name}</div>
                          </td>
                            <td className="text-center">
                              {
                                formatDateTime(userDataa.created_datetime)
                                  .formattedDate
                              }
                            </td>
                            <td className="text-center">
                              {userDataa.bucket_capacity_in_gb}
                            </td>
                            <td className="text-center">{userDataa.group}</td>

                            <td>{userDataa.parent_id}</td>
                            <td className="text-center">
                              {userDataa.user_capacity}
                            </td>
                            <td>
                              <Link
                                className={`update-icon icon-with-space`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Edit User Detail"
                                onClick={() =>
                                  showSubmit(
                                    userDataa.host_email,
                                    userDataa.bucket_capacity_in_gb,

                                    userDataa.user_capacity,

                                    userDataa.is_account_active,
                                    userDataa.is_partner_admin,

                                    reset()
                                  )
                                }
                              >
                                <i className="fa-sharp fa-solid fa-pen"></i>
                              </Link>
                            </td>

                            <td>
                              <Link
                                className={`delete-icon icon-with-space`}
                                onClick={() => deleteUser(userDataa.host_email)}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Delete User"
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <>
                        <td colSpan={7} className="text-center"> No Data Available</td>
                      </>
                    )}
                  </>
                )}
              </table>
            </div>
            <div className="table-header pt-3 pb-3">
              <p className="text-lgt align-items-start flex-column">
                {/* <span className="card-label mb-1">
            Showing 1 to 10 of 50 entries
          </span> */}
              </p>

              {filteredData ? (
                <div className="card-toolbar">
                  {/* ... */}
                  <ReactPaginate
                    onPageChange={paginate}
                    pageCount={Math.ceil(filteredData.length / postsPerPage)}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    containerClassName={"pagination"}
                    pageLinkClassName={"page-number"}
                    previousLinkClassName={"page-number"}
                    nextLinkClassName={"page-number"}
                    activeLinkClassName={"active"}
                  />
                </div>
              ) : (
                <div className="loading">Loading..</div>
              )}
            </div>
            <div className="table-header pt-3 pb-3">
              <p className="text-lgt align-items-start flex-column"></p>
            </div>
          </div>
        </div>
        {/*Edit user detail Modal*/}

        <Modal show={showEditUserModal} onHide={handleCloseUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div
                variant="btn btn-sm btn-primary"
                onClick={handleCloseUserModal}
              >
                <h5>Edit User Detail</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-none px-4">
            <form>
              <div className="profile-form">
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder=""
                    required
                    name="email"
                    value={currentemail}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Storage Capacity(GB)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    required
                    name="currentBucketCapacity"
                    {...register("currentBucketCapacity", {
                      required: true,
                      value: currentBucketCapacity,
                    })}
                    readOnly
                  />
                  {errors.currentBucketCapacity && (
                    <span className="text-danger">
                      {errors.currentBucketCapacity.message}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    User Capacity(GB)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    required
                    name="currentCapacityValue"
                    {...register("currentCapacityValue", {
                      value: currentCapacityValue,
                      required: true,
                      onChange: (e) => setCurrentCapacityValue(e.target.value),
                    })}
                    disabled={adminEmailPayAsYouGo}
                  />
                  {errors.currentCapacityValue && (
                    <span className="text-danger">
                      {errors.currentCapacityValue.message}
                    </span>
                  )}
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck2"
                    name="is_group_admin"
                    checked={currentIsGroupAdmin}
                    onChange={(e) => setCurrentIsGroupAdmin(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck2">
                    Partner Admin
                  </label>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="is_account_active"
                    checked={currentIsAccountActive}
                    onChange={(e) =>
                      setCurrentIsAccountActive(e.target.checked)
                    }
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Account Active
                  </label>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-sm secondary"
              onClick={handleCloseUserModal}
            >
              Cancel
            </Button>
            <Button
              variant="btn btn-sm btn-primary"
              onClick={handleSubmit(handleUpdateSubmit)}
              disabled={!currentCapacityValue}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <FooterComponent />
      </LayoutComponent>
    </div>
  );
};

export default ViewUser;
