import React from "react";

const FooterComponent = () => {
  return (
    <footer className="footer p-4">
      <div className="container text-center">
        <div className="copyright-txt">
          <span>Genese ©2023</span>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
