import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import samplefile from "../../assests/sample_upload_file.csv";

const UploadCSVFileModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      axios
        .post("https://userbackendocss.varosatech.com/upload-file", formData, {
          withCredentials: true,
        })
        .then((response) => {
          toast.success("User created successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          navigate("/");
          // console.log(response);
          setLoading(false);
        })
        .catch((error) => {
          let errorMessage = error.message;
          if (error.response && error.response.data) {
            errorMessage = error.response.data.error || error.response.data;
          }

          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setLoading(false);
        })
        .finally(() => {
          setSelectedFile(null);
          setIsOpen(false);
        });
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div className="text-end pt-3">
        {/* <button className="btn upload-csv-button" onClick={handleOpenModal}>
          {" "}
          <i
            className="fa-solid fa-file-csv"
            style={{ color: "#217043", fontSize: "30px" }}
          ></i>
          <span className="mx-2">Upload</span>
        </button> */}
        <button
          className="btn-primary-update "
          onClick={handleOpenModal}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Create bulk users"
        >
          {" "}
          <i className="bi bi-upload"></i>{" "}
          <span className="mx-2">Upload CSV File to Create Users</span>
        </button>
      </div>

      <Modal show={isOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div variant="btn btn-sm btn-primary">
              <h6>Upload a CSV File</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sample File:</p>
          <a href={samplefile} download>
            <i className="bi bi-download"></i> Sample File
          </a>
          <div className="my-3">
            <p className="my-2">Choose a File:</p>
            <div>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                className="btn btn-sm btn-primary"
                onClick={handleButtonClick}
              >
                <span className="mx-2">Upload </span>
              </button>
            </div>

            {selectedFile && (
              <div>
                <h6>Selected File:</h6>
                <p>{selectedFile.name}</p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-sm secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="btn btn-sm btn-primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadCSVFileModal;
