import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../api";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import LayoutComponent from "../../shared/LayoutComponent";

const CreateAdminUser = () => {
  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [display_name, setDisplayName] = useState();
  const [loggedemail, setLoggedEmail] = useState(loggedin_user_email);
  const [user_capacity, setUserCapacity] = useState();

  const [bucket_capacity_in_gb, setBucketCapacity] = useState();
  const [bucket_name, setBucketName] = useState();
  const [group, setGroup] = useState();
  const [admin_email, setAdminEmail] = useState();
  const [is_account_active, setIsAccountActive] = useState(true);
  const [is_group_admin, setIsGroupAdmin] = useState(false);
  const [is_pay_as_you_go, setIsPayasyouGo] = useState(false);
  const [is_super_admin, setIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checks, setChecks] = useState({
    capsLetterCheck: false,
    numberCheck: false,
    pwdLengthCheck: false,
    specialCharCheck: false,
  });
  const [error, setError] = useState();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    display_name: yup.string().required("Display Name is required"),
    // user_capacity: yup
    //   .string()
    //   .required("User Capacity is required")
    //   .matches(/^(0|[1-9]\d*)$/, "User capacity must be a non-negative number"),

    bucket_capacity_in_gb: yup
      .string()
      .required("bucket Capacity is required")
      .matches(
        /^(0|[1-9]\d*)$/,
        "Bucket capacity must be a non-negative number"
      ),

    bucket_name: yup.string().required("Bucket Name is required"),
    group: yup.string().required("Group is required"),
    admin_email: yup
      .string()
      .required("Email  is required")
      .email("Invalid email address"),
    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .min(8, "Password must be at least 8 characters long")
    //   .matches(
    //     /^(?=.*[!@#$%^&*])/,
    //     "Password must include at least one special character"
    //   ),
    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .min(8, "Password must be at least 8 characters long")
    //   .matches(/[A-Z]/, "Password must include at least one uppercase letter")
    //   .matches(/[a-z]/, "Password must include at least one lowercase letter")
    //   .matches(/[0-9]/, "Password must include at least one digit")
    //   .matches(
    //     /^(?=.*[!@#$%^&*])/,
    //     "Password must include at least one special character"
    //   ),
    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
    //   ),
    password: yup
      .string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must include at least one uppercase letter")
      .min(8, "Password must be at least 8 characters long")

      .matches(/[a-z]/, "Password must include at least one lowercase letter")
      .matches(/[0-9]/, "Password must include at least one digit")
      .matches(
        /[^A-Za-z0-9]/,
        "Password must include at least one special character"
      ),
    user_capacity: yup
      .string()
      .required("User Capacity is required")
      .matches(/^(0|[1-9]\d*)$/, "User capacity must be a non-negative number")
      .test(
        "lessThanBucketCapacity",
        "User capacity should be less than bucket capacity",
        function (value) {
          const bucketCapacity = parseInt(this.parent.bucket_capacity_in_gb);
          const userCapacity = parseInt(value);
          return userCapacity <= bucketCapacity;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      display_name: "",
      user_capacity: "",
      bucket_name: "",
      bucket_capacity_in_gb: "",
      group: "",
      admin_email: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const handlepartnerCheckboxChange = (event) => {
    setIsGroupAdmin(event.target.checked);
  };

  const handlepayCheckboxChange = (event) => {
    setIsPayasyouGo(event.target.checked);
    if (event.target.checked) {
      setUserCapacity("0");
      setBucketCapacity("0");
    } else {
      setUserCapacity("");
      setBucketCapacity("");
    }
  };

  useEffect(() => {
    if (is_pay_as_you_go) {
      if (getValues("user_capacity") !== "0") {
        setValue("user_capacity", "0", {
          shouldValidate: true,
        });
      }
      if (getValues("bucket_capacity_in_gb") !== "0") {
        setValue("bucket_capacity_in_gb", "0", {
          shouldValidate: true,
        });
      }
    } else {
      if (getValues("user_capacity") !== "") {
        setValue("user_capacity", "", {
          shouldValidate: true,
        });
      }
      if (getValues("bucket_capacity_in_gb") !== "") {
        setValue("bucket_capacity_in_gb", "", {
          shouldValidate: true,
        });
      }
    }
  }, [is_pay_as_you_go, setValue, getValues]);

  // const handleOnChange = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);
  //   setChecks({
  //     capsLetterCheck: /[A-Z]/.test(value),
  //     numberCheck: /[0-9]/.test(value),
  //     pwdLengthCheck: value.length >= 8,
  //     specialCharCheck: /[!@#$%^&*(),.?":{}|<>]/.test(value),
  //   });
  // };

  const handleFormSubmit = async () => {
    setLoading(true);
    const formData = {
      email: email,
      password: password,
      display_name: display_name,
      // loggedemail: loggedemail,
      user_capacity: user_capacity,
      bucket_name: bucket_name,
      bucket_capacity_in_gb: bucket_capacity_in_gb,
      group: group,
      admin_email: admin_email,
      is_group_admin: is_group_admin ? true : false,
      is_pay_as_you_go: is_pay_as_you_go ? true : false,
      is_account_active: is_account_active,
      is_super_admin: is_super_admin,
    };

    const params = {
      user: loggedemail,
    };

    // console.log(formData);

    api
      .post("/create", formData, {
        params,
      })
      .then(function (response) {
        setEmail("");
        setPassword("");
        setDisplayName("");
        setLoggedEmail("");
        setUserCapacity("");
        setBucketCapacity("");
        setBucketName("");
        setGroup("");
        setAdminEmail("");
        setIsGroupAdmin("");
        setIsPayasyouGo("");
        setIsSuperAdmin("");
        setIsAccountActive("");
        toast.success("User created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/");
        // console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        setLoading(false);
      });
  };

  return (
    <>
      {/* Dashboard */}

      {/* <div className="content py-3"> */}
      <LayoutComponent>
        <div className="table-content pt-5">
          <div className="container">
            <div className="profile-form w-75 w-100-mob">
              <h6 className="card-title align-items-start flex-column pb-4">
                Create Admin
              </h6>
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  ></button>
                </div>
              )}
              <div className="row">
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    {...register("email", {
                      required: true,

                      onChange: (e) => setEmail(e.target.value),
                    })}
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    className="form-control bg-transparent"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Password must contain at least 8 characters,
    one uppercase letter,
    one lowercase letter,
    one number, and
    one special character"
                    {...register("password", {
                      required: true,
                      onChange: (e) => setPassword(e.target.value),
                    })}
                  />
                  {errors.password && (
                    <span className="text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Display Name
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    name="display_name"
                    {...register("display_name", {
                      required: true,

                      onChange: (e) => setDisplayName(e.target.value),
                    })}
                  />
                  {errors.display_name &&
                    errors.display_name.type === "required" && (
                      <span className="text-danger">
                        *Display Name is required
                      </span>
                    )}
                </div>
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Logged In Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="company"
                    value={loggedin_user_email}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Storage Capacity(GB)
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    name="display_name"
                    {...register("bucket_capacity_in_gb", {
                      required: true,

                      onChange: (e) => setBucketCapacity(e.target.value),
                    })}
                    readOnly={is_pay_as_you_go}
                  />
                  {errors.bucket_capacity_in_gb && (
                    <span className="text-danger">
                      {errors.bucket_capacity_in_gb.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Bucket Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="company"
                    {...register("bucket_name", {
                      required: true,

                      onChange: (e) => setBucketName(e.target.value),
                    })}
                  />
                  {errors.bucket_name &&
                    errors.bucket_name.type === "required" && (
                      <span className="text-danger">
                        *Bucket Name is required
                      </span>
                    )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Group
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    name="display_name"
                    {...register("group", {
                      required: true,

                      onChange: (e) => setGroup(e.target.value),
                    })}
                  />
                  {errors.group && errors.group.type === "required" && (
                    <span className="text-danger">*Group is required</span>
                  )}
                </div>
                <div className="col-md-6 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    User Capacity(GB)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="company"
                    {...register("user_capacity", {
                      required: true,

                      onChange: (e) => setUserCapacity(e.target.value),
                    })}
                    readOnly={is_pay_as_you_go}
                  />
                  {errors.user_capacity && (
                    <span className="text-danger">
                      {errors.user_capacity.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pb-2">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Partner Admin Email
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    name="admin_email"
                    {...register("admin_email", {
                      required: true,

                      onChange: (e) => setAdminEmail(e.target.value),
                    })}
                  />
                  {errors.admin_email && (
                    <span className="text-danger">
                      {errors.admin_email.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 pb-2">
                  <div className="form-rem">
                    <div className="mt-1 form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={is_account_active}

                        // onChange={handleaccoutCheckboxChange}
                      />

                      <label
                        className="form-check-label "
                        htmlFor="exampleCheck1"
                        // style={{ marginLeft: "8px" }}
                      >
                        Active
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pb-2">
                  <div className="form-rem">
                    <div className="mt-1 form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck2"
                        checked={is_group_admin}
                        onChange={handlepartnerCheckboxChange}
                      />

                      <label
                        className="form-check-label "
                        htmlFor="exampleCheck2"
                        // style={{ marginLeft: "8px" }}
                      >
                        Admin
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pb-2">
                  <div className="form-rem">
                    <div className="mt-1 form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck3"
                        checked={is_pay_as_you_go}
                        onChange={handlepayCheckboxChange}
                      />

                      <label
                        className="form-check-label "
                        htmlFor="exampleCheck3"
                        // style={{ marginLeft: "8px" }}
                      >
                        Pay As You Go
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pb-2">
                  <div className="form-rem">
                    <div className="mt-1 form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck4"
                        value={is_super_admin}
                      />

                      <label
                        className="form-check-label "
                        htmlFor="exampleCheck4"
                        // style={{ marginLeft: "8px" }}
                      >
                        Super Admin
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                  <div className="col pb-4">
                    <label htmlFor="formGroupExampleInput" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                </div> */}

              <div className=" py-2 d-grid gap-2">
                {!loading && (
                  <button
                    type="submit"
                    onClick={handleSubmit(handleFormSubmit)}
                    className=" btn btn-sm btn-primary mt-4 button-width"
                  >
                    Submit
                  </button>
                )}

                {loading && (
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 button-width"
                    disabled
                  >
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>

      {/* <style>
        {`
          .password-requisite ul li.valid {
            color: green;
          }

          .password-requisite ul li.invalid {
            color: red;
          }
        `}
      </style> */}
    </>
  );
};

export default CreateAdminUser;
