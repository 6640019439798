import React, { useState, useEffect } from "react";

import ViewAdminUser from "./Super_Admin/ViewAdminUser";
import ViewUser from "./Client_Admin/ViewUser";
import apiService from "../service/service";
import { useContext } from "react";
import ExchangeApiContext from "../context/Exchangecontext/exchangeapicontext";

const ViewPage = () => {
  const context = useContext(ExchangeApiContext);
  const { role, getexchangetoken } = context;
  useEffect(() => {
    getexchangetoken();
  });
  // console.log(role);
  return (
    <>
      {role === "super_admin" ? (
        <ViewAdminUser />
      ) : (
        role === "client_admin" && <ViewUser />
      )}
    </>
  );
};

export default ViewPage;
