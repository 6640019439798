import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ExchangeApiContext from "../../context/Exchangecontext/exchangeapicontext";
import api from "../../api";
import LayoutComponent from "../../shared/LayoutComponent";

const CreateUser = () => {
  const context = useContext(ExchangeApiContext);
  const { loggedin_user_email } = context;

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [display_name, setDisplayName] = useState();
  const [userdata, setuserData] = useState([]);
  const [loggedemail, setLoggedEmail] = useState(loggedin_user_email);
  const [user_capacity, setUserCapacity] = useState();
  const [is_group_admin, setISGroupAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminemailpayasyougo, setAdminEmailPayAsYouGo] = useState();
  // const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  // const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [error, setError] = useState();
  // const [checks, setChecks] = useState({
  //   capsLetterCheck: false,
  //   numberCheck: false,
  //   pwdLengthCheck: false,
  //   specialCharCheck: false,
  // });

  // const handleCheckboxChange = (event) => {
  //   setISGroupAdmin(event.target.checked);
  // };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    display_name: yup.string().required("Display Name is required"),
    user_capacity: yup
      .string()
      .required("User Capacity is required")
      .min(0, "User capacity must be a non-negative number")
      .matches(/^(0|[1-9]\d*)$/, "User capacity must be a positive number"),

    password: yup
      .string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must include at least one uppercase letter")
      .min(8, "Password must be at least 8 characters long")

      .matches(/[a-z]/, "Password must include at least one lowercase letter")
      .matches(/[0-9]/, "Password must include at least one digit")
      .matches(
        /[^A-Za-z0-9]/,
        "Password must include at least one special character"
      ),
    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
    //   ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      display_name: "",
      user_capacity: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const handleCheckboxChange = (event) => {
    setISGroupAdmin(event.target.checked);
  };

  // const handleOnChange = (e) => {
  //   const value = e.target.value;

  //   const updatedChecks = {
  //     capsLetterCheck: /[A-Z]/.test(value),
  //     numberCheck: /[0-9]/.test(value),
  //     pwdLengthCheck: value.length >= 8,
  //     specialCharCheck: /[^A-Za-z0-9]/.test(value),
  //   };
  //   setChecks(updatedChecks);
  //   setPassword(value);
  // };

  const params = {
    user: loggedin_user_email,
  };

  useQuery("userData", async () => {
    try {
      const response = await api.get(`/view`, {
        params,
      });
      const clientAdminData = JSON.parse(response.data.body);
      setuserData(clientAdminData);
      const adminemailpayasyougo = clientAdminData.some(
        (admin) => admin.is_pay_as_you_go
      );

      setAdminEmailPayAsYouGo(adminemailpayasyougo);
      if (adminemailpayasyougo) {
        if (getValues("user_capacity") !== "0") {
          setValue("user_capacity", "0", {
            shouldValidate: true,
          });
        }
      } else {
        if (getValues("user_capacity") !== "") {
          setValue("user_capacity", "", {
            shouldValidate: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleFormSubmit = async (adminemailpayasyougo) => {
    setLoading(true);
    const formData = {
      email: email,
      password: password,
      display_name: display_name,
      admin_email: loggedemail,
      user_capacity: adminemailpayasyougo ? 0 : user_capacity,
      is_group_admin: is_group_admin ? true : false,
    };

    const params = {
      user: loggedemail,
    };

    api
      .post("/create", formData, {
        params,
      })
      .then(function (response) {
        setEmail("");
        setPassword("");
        setDisplayName("");
        setLoggedEmail("");
        setUserCapacity("");
        setISGroupAdmin("");
        toast.success("User created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/");
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setLoading(false);
      });
  };

  // const handleOnFocus = () => {
  //   setIsPasswordFocused(true);
  // };

  // const handleOnBlur = () => {
  //   setIsPasswordFocused(false);
  // };

  return (
    <>
      <LayoutComponent>
        <div className="content py-5 px-5">
          <div className="container mb-4">
            <div>
              <div className="d-flex justify-content-center">
                <div className="account-pass w-40 w-100-mob">
                  <h6 className="card-title align-items-start flex-column pb-4">
                    Create User
                  </h6>
                  {error && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      {error}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setError("")}
                      ></button>
                    </div>
                  )}
                  <div className=" mb-2 fm">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control bg-transparent"
                      {...register("email", {
                        required: true,

                        onChange: (e) => setEmail(e.target.value),
                      })}
                    />
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  {/* <div className="mb-2 fm">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control bg-transparent"
                      {...register("password")}
                      onChange={(e) => handleOnChange(e)}
                      onFocus={handleOnFocus}
                      onBlur={handleOnBlur}
                    />

                    {errors.password && (
                      <div className="invalid-feedback">
                        {errors.password.message}
                      </div>
                    )}
                    <div className="form-text">
                      {!errors.password && isPasswordFocused && (
                        <p>
                          <span
                            className={`text-${
                              checks.capsLetterCheck ? "success" : "danger"
                            } inline small`}
                          >
                            {checks.capsLetterCheck
                              ? "✔ Contains uppercase letter"
                              : "✖ Must contain uppercase letter"}
                          </span>{" "}
                          <span
                            className={`text-${
                              checks.numberCheck ? "success" : "danger"
                            } inline small`}
                          >
                            {checks.numberCheck
                              ? "✔ Contains number"
                              : "✖ Must contain number"}
                          </span>{" "}
                          <span
                            className={`text-${
                              checks.pwdLengthCheck ? "success" : "danger"
                            } inline small`}
                          >
                            {checks.pwdLengthCheck
                              ? "✔ Minimum 8 characters"
                              : "✖ Minimum 8 characters"}
                          </span>{" "}
                          <span
                            className={`text-${
                              checks.specialCharCheck ? "success" : "danger"
                            } inline small`}
                          >
                            {checks.specialCharCheck
                              ? "✔ Contains special character"
                              : "✖ Must contain special character"}
                          </span>
                        </p>
                      )}
                      {errors.password && (
                        <p className="text-danger inline">
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                  </div> */}

                  <div className=" mb-2 fm">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control bg-transparent"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Password must contain at least 8 characters,
      one uppercase letter,
      one lowercase letter,
      one number, and
      one special character"
                      {...register("password", {
                        required: true,

                        onChange: (e) => setPassword(e.target.value),
                      })}
                    />
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </div>

                  <div className=" mb-2 fm">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Display Name
                    </label>
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      {...register("display_name", {
                        required: true,

                        onChange: (e) => setDisplayName(e.target.value),
                      })}
                    />
                    {errors.display_name &&
                      errors.display_name.type === "required" && (
                        <span className="text-danger">
                          *Display Name is required
                        </span>
                      )}
                  </div>
                  <div className="mb-2 fm">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      Logged In Email
                    </label>
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      value={loggedin_user_email}
                      disabled
                    />
                  </div>
                  <div className=" mb-2 fm">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="form-label"
                    >
                      User Capacity(GB)
                    </label>
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      {...register("user_capacity", {
                        required: true,

                        onChange: (e) => setUserCapacity(e.target.value),
                      })}
                      readOnly={adminemailpayasyougo}
                    />
                    {errors.user_capacity && (
                      <span className="text-danger">
                        {errors.user_capacity.message}
                      </span>
                    )}
                  </div>
                  <div className="form-rem">
                    <div className="mt-3 form-check ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={is_group_admin}
                        onChange={handleCheckboxChange}
                      />

                      <label
                        className="form-check-label "
                        htmlFor="exampleCheck1"
                        style={{ marginLeft: "8px" }}
                      >
                        Admin
                      </label>
                    </div>
                  </div>

                  <div className="mb-3 py-2 d-grid gap-2">
                    {!loading && (
                      <button
                        onClick={handleSubmit(handleFormSubmit)}
                        type="submit"
                        className="btn btn-sm btn-primary mt-4"
                      >
                        Submit
                      </button>
                    )}

                    {loading && (
                      <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        disabled
                      >
                        <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <FooterComponent /> */}
        </div>
      </LayoutComponent>
      <style>
        {`
          .password-requisite ul li.valid {
            color: green;
          }

          .password-requisite ul li.invalid {
            color: red;
          }
        `}
      </style>
    </>
  );
};

export default CreateUser;
